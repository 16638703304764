import { Directive, ElementRef, Input } from '@angular/core';
import { ContactLayerService } from './contact-layer.service';
import { PageUrls } from '../../routing/page-urls';

@Directive({
	selector: '[chShowContactLayerConditionally]',
})
export class ShowContactLayerConditionallyDirective {

	@Input('chShowContactLayerConditionally')
	target: string[] | string;

	constructor(
		protected theElement: ElementRef,
		protected service: ContactLayerService,
	) {

		this.theElement.nativeElement.addEventListener('click', (event: MouseEvent) => {
			let url = this.target;
			if (!this.target && this.theElement.nativeElement.href) {
				url = this.theElement.nativeElement.href;
			}

			if (url === PageUrls.KONTAKT.join('/') || (Array.isArray(url) && url.join('/') === PageUrls.KONTAKT.join('/'))) {
				this.service.showContactLayer();
				event.preventDefault();
				event.stopPropagation();
			}
		});

	}

}
