import { Injectable } from '@angular/core';
import { SearchLayerComponent } from './search-layer/search-layer.component';
import { SearchEngineService } from './search-engine.service';

@Injectable({
	providedIn: 'root',
})
export class SearchService {

	protected component?: SearchLayerComponent;

	constructor(
		protected searchEngine: SearchEngineService,
	) {
	}

	openSearchField(): void {
		this.component?.show();
	}

	closeSearchField(): void {
		this.component?.hide();
	}

	registerTheComponent(theComponent: SearchLayerComponent): void {
		if (this.component) {
			throw new Error('Component is already registered');
		}
		this.component = theComponent;
	}


}
