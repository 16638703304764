import { NgModule } from '@angular/core';
import { RelativeTimePipe } from '../pipes/relative-time.pipe';
import { PluralPipe } from '../pipes/plural.pipe';
import { Nl2brPipe } from '../pipes/nl2br.pipe';
import { SpecCharsPipe } from '../pipes/spec-chars.pipe';
import { FormatFileSizePipe } from '../pipes/format-file-size.pipe';
import { NumberFormatPipe } from '../pipes/number-format.pipe';
import { CzkPipe } from '../pipes/czk.pipe';
import { RepeatPipe } from '../pipes/repeat.pipe';
import { HumanizeUrlPipe } from '../pipes/humanize-url.pipe';
import { TechnicalizeUrlPipe } from '../pipes/technicalize-url.pipe';
import { CreateLinkPipe } from '../pipes/create-link.pipe';
import { StripTagsPipe } from '../pipes/strip-tags.pipe';
import { ShortenPipe } from '../pipes/shorten.pipe';
import { PhoneNumberFormatPipe } from '../pipes/phone-number-format.pipe';
import { IntersectionObserverDirective } from '../directives/intersection-observer.directive';

@NgModule({
	declarations: [
		RelativeTimePipe,
		PluralPipe,
		Nl2brPipe,
		SpecCharsPipe,
		FormatFileSizePipe,
		NumberFormatPipe,
		CzkPipe,
		RepeatPipe,
		HumanizeUrlPipe,
		TechnicalizeUrlPipe,
		CreateLinkPipe,
		StripTagsPipe,
		ShortenPipe,
		PhoneNumberFormatPipe,
		IntersectionObserverDirective,
	],
	exports: [
		RelativeTimePipe,
		PluralPipe,
		Nl2brPipe,
		SpecCharsPipe,
		FormatFileSizePipe,
		NumberFormatPipe,
		CzkPipe,
		RepeatPipe,
		HumanizeUrlPipe,
		TechnicalizeUrlPipe,
		CreateLinkPipe,
		StripTagsPipe,
		ShortenPipe,
		PhoneNumberFormatPipe,
		IntersectionObserverDirective,
	],
})
export class OkModule {

}
