<a class="box" [routerLink]="link">
	<div class="image-part" *ngIf="image">
		<img
			[alt]="title"
			[src]="image | src:212"
			[srcset]="image | srcset:212"
			[sizes]="212 | sizes:'100px'"
		/>
	</div>
	<div class="text-part">
		<h3 class="name" [innerHTML]=" title | pseudoTags ">

		</h3>
		<div class="subtitle">
			{{ subtitle }}
		</div>
	</div>
</a>
