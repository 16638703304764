import { Component, OnInit } from '@angular/core';
import { DataService } from './data/data.service';
import { ApiService } from './api/api.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { isScullyRunning } from '@scullyio/ng-lib';

/* tslint:disable:no-string-literal */

@Component({
	selector: 'ch-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'chemiestar';

	public blueHeaderMode = false;

	constructor(
		protected dataService: DataService,
		protected apiService: ApiService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected titleService: Title,
	) {
		// @ts-ignore
		window.s = {
			dataService,
			apiService,
		};

		this.router.events.subscribe(
			(e: RouterEvent) => {
				if (e instanceof NavigationEnd) {
					let fc = this.router.routerState.snapshot.root.firstChild;
					if (fc) {
						this.blueHeaderMode = this.isBlueHeaderActive(fc) || false;
					}

					if (!isScullyRunning()) {
						if (window['gtag']) {
							setTimeout(
								() => {
									window['gtag']('event', 'page_view', {
										page_title: this.titleService.getTitle(),
										page_path: e.url,
										page_location: window.location + '',
										send_to: 'UA-23731241-1',
									});
									window['gtag']('event', 'page_view', {
										page_title: this.titleService.getTitle(),
										page_path: e.url,
										page_location: window.location + '',
										send_to: 'G-5NN782GSN0',
									});
								},
								100,
							);
						}
					}

				}
			},
		);
	}

	ngOnInit(): void {
		// tslint:disable-next-line
		var leady_track_key = 'jjZ7i0iIYSzj7V6V';
		// tslint:disable-next-line
		window['leady_track_key'] = leady_track_key;
		// tslint:disable-next-line
		(function() {
			// tslint:disable-next-line
			var l = document.createElement('script');
			l.type = 'text/javascript';
			l.async = true;
			// tslint:disable-next-line
			l.src = 'https://ct.leady.com/' + leady_track_key + '/L.js';
			// tslint:disable-next-line
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(l, s);
		})();
	}

	protected isBlueHeaderActive(route: ActivatedRouteSnapshot): boolean {
		if (!route) {
			return false;
		}
		if (route.data?.blueHeader === 'yes') {
			return true;
		} else if (route.data?.blueHeader === 'children') {
			return this.isBlueHeaderActive(route.firstChild);
		}
	}

}
