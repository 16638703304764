import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSrcPipe } from './image-src.pipe';
import { ImageSrcsetPipe } from './image-srcset.pipe';
import { ImageSizesPipe } from './image-sizes.pipe';
import { AnimateInViewportDirective } from './animate-in-viewport.directive';
import { PseudoTagsPipe } from './pseudo-tags.pipe';
import { AddToRouterLinkPipe } from './add-to-router-link.pipe';
import { CreateImageAssetPipe } from './create-image-asset.pipe';
import { ImageSrcsetCustomPipe } from './image-srcset-custom.pipe';
import { TechnologyNamePipe } from './technology-name.pipe';
import { HandleToUrlPipe } from './handle-to-url.pipe';
import { ShowContactLayerDirective } from './contact-layer/show-contact-layer.directive';
import { ShowContactLayerConditionallyDirective } from './contact-layer/show-contact-layer-conditionally.directive';
import { IfNotContactLayerPipe } from './contact-layer/if-not-contact-layer.pipe';
import { OkModule } from '../submodules/ok-angular-tools';
import { SearchResultStandardComponent } from './search/search-result-standard/search-result-standard.component';
import { RouterModule } from '@angular/router';
import { SearchResultSmallComponent } from './search/search-result-small/search-result-small.component';


@NgModule({
	declarations: [
		ImageSrcPipe,
		ImageSrcsetPipe,
		ImageSizesPipe,
		AnimateInViewportDirective,
		PseudoTagsPipe,
		AddToRouterLinkPipe,
		CreateImageAssetPipe,
		ImageSrcsetCustomPipe,
		TechnologyNamePipe,
		HandleToUrlPipe,
		ShowContactLayerDirective,
		ShowContactLayerConditionallyDirective,
		IfNotContactLayerPipe,
		SearchResultStandardComponent,
		SearchResultSmallComponent,
	],
	exports: [
		ImageSrcPipe,
		ImageSrcsetPipe,
		ImageSizesPipe,
		AnimateInViewportDirective,
		PseudoTagsPipe,
		AddToRouterLinkPipe,
		CreateImageAssetPipe,
		ImageSrcsetCustomPipe,
		TechnologyNamePipe,
		HandleToUrlPipe,
		ShowContactLayerDirective,
		ShowContactLayerConditionallyDirective,
		IfNotContactLayerPipe,
		SearchResultStandardComponent,
		SearchResultSmallComponent,
	],
	imports: [
		CommonModule,
		OkModule,
		RouterModule,
	],
})
export class CommonStuffModule {
}
