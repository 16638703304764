import { Component, OnInit } from '@angular/core';
import { PageUrls } from 'src/app/routing/page-urls';
import { MegaMenuItem, MegaMenuItems } from '../../../routing/menu-items';

@Component({
	selector: 'ch-main-menu-mobile-secondary-pane',
	templateUrl: './main-menu-mobile-secondary-pane.component.html',
	styleUrls: ['./main-menu-mobile-secondary-pane.component.scss'],
})
export class MainMenuMobileSecondaryPaneComponent implements OnInit {

	public productsMenu: MegaMenuItem[] = MegaMenuItems;

	public PageUrls = PageUrls;

	constructor() {
	}

	ngOnInit(): void {
	}

}
