import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ch-main-menu-mobile-button',
	templateUrl: './main-menu-mobile-button.component.html',
	styleUrls: ['./main-menu-mobile-button.component.scss'],
})
export class MainMenuMobileButtonComponent implements OnInit {

	@Input()
	open = false;

	@Input()
	blue = false;

	constructor() {
	}

	ngOnInit(): void {
	}

}
