import { Component, Input, OnInit } from '@angular/core';
import { ImageAsset } from '../../../models/image-asset';
import { RouterLinkStrings } from '../../../models/types/router-link-strings';

@Component({
	selector: 'ch-search-result-standard',
	templateUrl: './search-result-standard.component.html',
	styleUrls: ['./search-result-standard.component.scss'],
})
export class SearchResultStandardComponent implements OnInit {

	@Input()
	title: string;

	@Input()
	subtitle: string;

	@Input()
	image: ImageAsset;

	@Input()
	link: RouterLinkStrings;

	constructor() {
	}

	ngOnInit(): void {
	}

}
