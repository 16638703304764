<div class="pos size" [class.size--hiding]="hiding">
	<aside class="body" [class.body--hiding]="hiding">
		<div class="g-container">
			<div class="box">
				<div class="text">
					<div class="text-content" [innerHTML]="data.text">
					</div>
				</div>
				<ch-yellow-button class="link" [link]="data.link"></ch-yellow-button>
			</div>
		</div>
	</aside>
</div>
