import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from '../../ok-tools';

@Pipe({
	name: 'plural',
	pure: true,
})
export class PluralPipe implements PipeTransform {
	transform(value: number, one: string, ...args: string[]): string {
		// @ts-ignore
		return pluralize.apply(this, [value, one, ...args]);
	}
}


