import { createPerson, Person } from './person';
import { factory } from '../submodules/ok-tools';

export interface PersonGroup {

	name: string;
	note: string;
	displayType: 'small' | 'big';

}

export interface PersonGroupWithPersons extends PersonGroup {
	persons: Person[];
}


// tslint:disable-next-line:no-any
export function createPersonGroup(source: any): PersonGroup {

	return factory<PersonGroup>(
		source,
		{
			string: ['name', 'note'],
			enum: {
				displayType: ['small', 'big'],
			},
			default: {
				displayType: 'big',
			},
		},
	);

}

// tslint:disable-next-line:no-any
export function createPersonGroupWithPersons(source: any): PersonGroupWithPersons {
	return {
		...createPersonGroup(source),
		...factory(
			source,
			{
				subItems: {
					persons: createPerson,
				},
			},
		),
	};
}
