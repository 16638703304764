import { Component, Input, OnInit } from '@angular/core';
import { TopInfoStripData } from './top-info-strip-data';

@Component({
	selector: 'ch-top-info-strip',
	templateUrl: './top-info-strip.component.html',
	styleUrls: ['./top-info-strip.component.scss'],
})
export class TopInfoStripComponent implements OnInit {

	@Input()
	hiding = false;

	@Input()
	data: TopInfoStripData;

	constructor() {
	}

	ngOnInit(): void {

	}

}
