import { TechnologyPageType } from '../data/technology-page-type';
import { createDownloadItem, DownloadItem } from './download-item';
import { CaseStudyPreview, createCaseStudyPreview } from './case-study-preview';
import { createReferenceCompany, ReferenceCompany } from './reference-company';
import { createGlossaryItem, GlossaryItem } from './glossary-item';
import { createGalleryItem, GalleryItem } from './gallery-item';

export interface TechnologyPageDataModel {
	type: TechnologyPageType;
	downloads: DownloadItem[];
	caseStudies: CaseStudyPreview[];
	references: ReferenceCompany[];
	glossary: GlossaryItem[];
	photos: GalleryItem[];
}


// tslint:disable-next-line:no-any
export function createTechnologyPageDataModel(source: any): TechnologyPageDataModel {
	return {
		type: source.technology,
		downloads: source.downloads.map(createDownloadItem),
		caseStudies: source.caseStudies.map(createCaseStudyPreview),
		references: source.references.map(createReferenceCompany),
		glossary: source.glossaryItems.map(createGlossaryItem),
		photos: source.photogallery.map(createGalleryItem),
	} as TechnologyPageDataModel;
}
