import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HtmlHeadService {

	constructor(
		protected title: Title,
		protected meta: Meta,
		@Inject(DOCUMENT) protected document: HTMLDocument,
	) {
	}

	setTitle(title: string): void {
		this.title.setTitle(title);
	}

	setRobots(robots: string | boolean): void {
		if (robots === true) {
			robots = 'index, follow';
		}
		if (robots === false) {
			robots = 'noindex, nofollow';
		}
		this.createOrUpdateTag('robots', robots);
	}

	setDescription(description: string): void {
		this.createOrUpdateTag('description', description);
	}

	setCanonicalUrl(url: string): void {
		this.createOrUpdateLink('canonical', url);
	}

	setPaginationUrls(prevUrl: string, nextUrl: string): void {
		this.createOrUpdateLink('prev', prevUrl);
		this.createOrUpdateLink('next', nextUrl);
	}

	setSocialTags(title: string, description: string, siteName = ''): void {
		this.createOrUpdateTag('og:title', title, true);
		this.createOrUpdateTag('twitter:title', title);
		this.createOrUpdateTag('og:description', description, true);
		this.createOrUpdateTag('twitter:description', description);
		this.createOrUpdateTag('twitter:card', 'summary_large_image');
		this.createOrUpdateTag('og:site_name', siteName, true);
	}

	setImage(src: string): void {
		this.createOrUpdateTag('og:image', src, true);
		this.createOrUpdateTag('twitter:image', src);
	}

	setAuthor(author: string): void {
		this.createOrUpdateTag('author', author);
	}

	// tslint:disable-next-line:no-any
	setStructuredData(data: any): void {
		let head = this.document.head;
		if (head) {
			let microDataElement = head.querySelector<HTMLScriptElement>('script[type="application/ld+json"][data-md]');
			if (microDataElement) {
				if (data) {
					microDataElement.innerHTML = JSON.stringify(data, null, '\t');
				} else {
					microDataElement.parentNode.removeChild(microDataElement);
				}
			} else {
				if (data) {
					let el = this.document.createElement('script') as HTMLScriptElement;
					el.type = 'application/ld+json';
					el.setAttribute('data-md', '');
					el.innerHTML = JSON.stringify(data, null, '\t');
					head.appendChild(el);
				}
			}
		}
	}

	protected createOrUpdateTag(name: string, content: string, usePropertyInsteadOfName = false): void {
		let selectorPart = usePropertyInsteadOfName ? 'property' : 'name';
		let tag = this.meta.getTag(selectorPart + '="' + name + '"');
		if (tag) {
			if (content) {
				tag.content = content;
			} else {
				tag.parentNode.removeChild(tag);
			}
		} else {
			if (content) {
				let dataToCreate = {
					content
				};
				dataToCreate[selectorPart] = name;
				this.meta.addTag(dataToCreate);
			}
		}
	}

	protected createOrUpdateLink(rel: string, href: string): void {
		let head = this.document.head;
		if (head) {
			let linkElement = head.querySelector<HTMLLinkElement>('link[rel="' + rel + '"]');
			if (linkElement) {
				if (href) {
					linkElement.href = href;
				} else {
					linkElement.parentNode.removeChild(linkElement);
				}
			} else {
				if (href) {
					linkElement = this.document.createElement('link') as HTMLLinkElement;
					linkElement.rel = rel;
					linkElement.href = href;
					head.appendChild(linkElement);
				}
			}
		}
	}
}

