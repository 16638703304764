<div class="g-container megamenu--animated" [class.megamenu--before-enter]="!visible">
	<div class="main">
		<nav class="box" aria-label="Technologie" role="navigation">
			<a
				*ngFor="let item of items; let index = index"
				[routerLink]="item.link"
				routerLinkActive="big-link--active"
				class="box-item big-link animated-part"
				[style.--stagger]="index"
			>
				<span class="image">
					<img
						class="image-img"
						[alt]="item.name"
						[src]="item.image | src:212"
						[srcset]="item.image | srcset:212"
						[sizes]="212 | sizes"
					>
				</span>
				<span class="label">
					{{ item.name }}
				</span>
				<span class="button-text g-arrow g-arrow--blue">
					Zjistit víc
				</span>
			</a>
		</nav>
	</div>

	<div class="bottom">

		<div class="aux">

			<nav class="aux-box" aria-label="Pomocné stránky" role="navigation">

				<a [routerLink]="PageUrls.KE_STAZENI" class="aux-link animated-part" [style.--stagger]="items.length">
					<span class="aux-icon-space">
						<img class="aux-icon" src="/assets/imgs/download.svg" alt="Ikonka 'ke stažení'" />
					</span>
					<span class="aux-label">Ke stažení</span>
				</a>
				<a [routerLink]="PageUrls.CERTIFIKACE" class="aux-link animated-part" [style.--stagger]="items.length + 1">
					<span class="aux-icon-space">
						<img class="aux-icon" src="/assets/imgs/certification.svg" alt="Ikonka 'certifikace'" />
					</span>
					<span class="aux-label">Certifikace</span>
				</a>
				<a [routerLink]="PageUrls.SLOVNIK_POJMU" class="aux-link animated-part" [style.--stagger]="items.length + 2">
					<span class="aux-icon-space">
						<img class="aux-icon" src="/assets/imgs/slovnik.svg" alt="Ikonka 'slovník'" />
					</span>
					<span class="aux-label">Slovník pojmů</span>
				</a>

			</nav>


		</div>

		<div class="contact-space">

			<ch-blue-button
				class="contact-button animated-part"
				[style.--stagger]="items.length + 3"
				[arrow]="true"
				[blurryThing]="true"
				chShowContactLayer
			>
				Kontaktujte nás!
			</ch-blue-button>

			<div class="dotted-bg g-dotted-bg animated-part" [style.--stagger]="2"></div>

		</div>
	</div>

</div>

