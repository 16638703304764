import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../search.service';
import * as bodyScrollLock from 'body-scroll-lock';
import * as debounce from 'debounce';
import { SearchEngineService, SearchResult } from '../search-engine.service';
import { PageUrls } from '../../../routing/page-urls';
import { filter } from 'rxjs/operators';
import { ActivationEnd, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { DownloadItem } from '../../../models/download-item';

@Component({
	selector: 'ch-search-layer',
	templateUrl: './search-layer.component.html',
	styleUrls: ['./search-layer.component.scss'],
})
export class SearchLayerComponent implements OnInit, OnDestroy {

	public visible = false;

	@ViewChild('bodyElement')
	public bodyElement: ElementRef<HTMLDivElement>;

	@ViewChild('theInput')
	public theInput: ElementRef<HTMLInputElement>;

	public loading = false;

	public searchString = '';

	result?: SearchResult;

	protected debouncedSearch = debounce(this.search, 800);

	public PageUrls = PageUrls;

	protected subsink = new SubSink();

	public showMoreLink = false;

	constructor(
		protected searchService: SearchService,
		protected searchEngine: SearchEngineService,
		protected router: Router,
	) {

		this.subsink.sink = this.router.events.pipe(filter((e) => (e instanceof ActivationEnd))).subscribe(
			(event: ActivationEnd) => {
				this.hide();
			},
		);

	}

	ngOnInit(): void {
		this.searchService.registerTheComponent(this);
		document.body.addEventListener('keyup', (event: KeyboardEvent) => {
			if (this.visible && event.key === 'Escape') {
				if (this.searchString) {
					this.searchString = '';
					event.stopPropagation();
					event.preventDefault();
				} else {
					this.hide();
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	show(): void {
		this.visible = true;
		this.updateScrollLock();
		this.theInput.nativeElement.focus();
		setTimeout(
			() => {
				this.theInput.nativeElement.focus();
			},
			200,
		);
	}

	hide(): void {
		this.visible = false;
		this.updateScrollLock();
	}

	protected updateScrollLock(): void {
		if (this.visible) {
			bodyScrollLock.disableBodyScroll(this.bodyElement.nativeElement);
		} else {
			bodyScrollLock.enableBodyScroll(this.bodyElement.nativeElement);
		}
	}

	fieldValueChanged(): void {
		this.debouncedSearch();
	}

	search(allResults = false): void {
		if (this.searchString.length >= 3) {
			this.loading = true;
			this.showMoreLink = false;
			this.searchEngine.search(this.searchString, allResults).then(
				(r) => {
					if (this.visible) {
						this.result = r;
						this.loading = false;

						if (r.references.count > r.references.items.length) {
							this.showMoreLink = true;
						}
						if (r.glossary.count > r.glossary.items.length) {
							this.showMoreLink = true;
						}
						if (r.downloads.count > r.downloads.items.length) {
							this.showMoreLink = true;
						}
						if (r.person.count > r.person.items.length) {
							this.showMoreLink = true;
						}
						if (r.caseStudies.count > r.caseStudies.items.length) {
							this.showMoreLink = true;
						}
						if (r.technologies.count > r.technologies.items.length) {
							this.showMoreLink = true;
						}
					}
				},
			);
		}
	}

	showAllItemsButtonClicked(): void {
		this.search(true);
	}

	openYoutube(item: DownloadItem): void {
		window.open(item.url.replace('/embed', '/watch'), '_blank');
	}
}
