import { Pipe, PipeTransform } from '@angular/core';
import { stripTags } from '../submodules/ok-tools/src/strip-tags';

@Pipe({
	name: 'pseudoTags',
	pure: true,
})
export class PseudoTagsPipe implements PipeTransform {

	protected regexpBold = new RegExp(/\*([^*]+)\*/, 'g');
	protected regexpBreak = new RegExp(/\|\|/, 'g');

	transform(source: string, toPlainText = false): string {

		if (!source) {
			return '';
		}

		source = stripTags(source);

		source = source.replace(this.regexpBold, (_, thePart) => {
			return '<b>' + thePart + '</b>';
		});

		source = source.replace(this.regexpBreak, '<br />');

		if (toPlainText) {
			source = stripTags(source);
		}

		return source;

	}

}
