import { Injectable } from '@angular/core';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { PhotoswipeImage } from './photoswipe-image';
import { Observable, Subject } from 'rxjs';

export interface PhotoswipeEvent {
	photoswipe: PhotoSwipe;
	displayed: boolean;
}

@Injectable()
export class PhotoswipeService {

	protected element: HTMLDivElement;

	protected _event = new Subject<PhotoswipeEvent>();

	public defaultOptions = {
		index: 0,
		loop: false,
		bgOpacity: 0.93,
		closeEl: true,
		captionEl: true,
		fullscreenEl: false,
		zoomEl: false,
		shareEl: false,
		counterEl: true,
		arrowEl: 'auto',
		preloaderEl: true,
		showHideOpacity: true,
	};

	constructor() {
	}


	static calculateSize(
		originalWidth: number,
		originalHeight: number,
		transformedMaxWidth: number,
		transformedMaxHeight: number,
	): { w: number, h: number } {

		let ratioW = transformedMaxWidth / originalWidth;
		let ratioH = transformedMaxHeight / originalHeight;

		let ratio = (ratioW < ratioH) ? ratioW : ratioH;

		if (ratio > 1) {
			return {
				w: originalWidth,
				h: originalHeight,
			};
		}

		return {
			w: originalWidth * ratio,
			h: originalHeight * ratio,
		};

	}


	get event(): Observable<PhotoswipeEvent> {
		return this._event.asObservable();
	}

	setElement(element: HTMLDivElement): void {

		if (this.element) {
			throw new Error('The Photoswipe\'s element has already been set!');
		}

		this.element = element;
	}

	openGallery(images: PhotoswipeImage[], initialIndex, initialImageElement: HTMLElement = null): Promise<void> {

		if (!this.element) {
			throw new Error('No Photoswipe\'s element has been set.');
		}

		// tslint:disable-next-line:no-any
		let thisOptions: any = {
			index: initialIndex,
			getThumbBoundsFn: (index) => {
				if (initialImageElement) {
					let rect = initialImageElement.getBoundingClientRect();
					if (rect.top && rect.left && initialImageElement.offsetWidth) {
						return {
							x: rect.left + window.scrollX,
							y: rect.top + window.scrollY,
							w: initialImageElement.offsetWidth,
						};
					} else {
						return null;
					}
				} else {
					return {
						x: (window.innerWidth / 2) - 40,
						y: (window.innerHeight / 2) - 40,
						w: 80,
					};
				}
			},
		};

		if (this.defaultOptions.arrowEl === 'auto') {
			thisOptions.arrowEl = !('ontouchstart' in window && navigator.maxTouchPoints > 0);
		}

		let options = Object.assign({}, this.defaultOptions, thisOptions);

		let gallery = new PhotoSwipe(
			this.element,
			PhotoSwipeUI_Default,
			images,
			options,
		);

		gallery.init();

		this._event.next({
			photoswipe: gallery,
			displayed: true,
		});

		return new Promise(
			(resolve, reject) => {
				gallery.listen('destroy', () => {
					this._event.next({
						photoswipe: gallery,
						displayed: false,
					});
					resolve();
				});
			},
		);

	}


}
