import { Pipe, PipeTransform } from '@angular/core';
import { RouterLinkStrings } from '../models/types/router-link-strings';

@Pipe({
	name: 'addToRouterLink',
})
export class AddToRouterLinkPipe implements PipeTransform {

	transform(value: RouterLinkStrings, added: RouterLinkStrings): RouterLinkStrings {
		return [...value, ...added];
	}

}
