import { Component, Input, OnInit } from '@angular/core';
import { UrlString } from '../../../models/types/url-string';

@Component({
	selector: 'ch-contact-item',
	templateUrl: './contact-item.component.html',
	styleUrls: ['./contact-item.component.scss'],
})
export class ContactItemComponent implements OnInit {

	@Input()
	public text;

	@Input()
	public link;

	@Input()
	public iconSrc: UrlString;

	@Input()
	public addBgToIcon = true;

	@Input()
	public tag: string;

	@Input()
	public color: string;

	@Input()
	public subText: string;

	@Input()
	public marginBottom: 'sm' | 'lg' = 'sm';

	constructor() {
	}

	ngOnInit(): void {
	}

}
