<nav role="navigation">

	<ul class="g-list-nelist products-menu">
		<li *ngFor="let menuItem of productsMenu">
			<a
				[routerLink]="menuItem.link"
				class="product-link"
				routerLinkActive="product-link--active"
			>
				<span class="label">
					{{ menuItem.name }}
				</span>
				<span class="image">
					<img
						class="image-img"
						[alt]="menuItem.name"
						[src]="menuItem.image | src:150"
						[srcset]="menuItem.image | srcset:150"
						sizes="calc(40vw - 30px)"
					>
				</span>
			</a>
		</li>
	</ul>

	<ul class="g-list-nelist aux-menu">
		<li>
			<a [routerLink]="PageUrls.KE_STAZENI" class="aux-link">
				<span class="aux-icon-space">
					<img class="aux-icon" src="/assets/imgs/download.svg" alt="Ikonka 'ke stažení'"/>
				</span>
				<span class="aux-label">Ke stažení</span>
			</a>
		</li>
		<li>
			<a [routerLink]="PageUrls.KE_STAZENI" class="aux-link">
				<span class="aux-icon-space">
					<img class="aux-icon" src="/assets/imgs/certification.svg" alt="Ikonka 'certifikace'"/>
				</span>
				<span class="aux-label">Certifikace</span>
			</a>
		</li>
		<li>
			<a [routerLink]="PageUrls.SLOVNIK_POJMU" class="aux-link">
				<span class="aux-icon-space">
					<img class="aux-icon" src="/assets/imgs/slovnik.svg" alt="Ikonka 'slovník'"/>
				</span>
				<span class="aux-label">Slovník pojmů</span>
			</a>
		</li>

	</ul>

	<div class="button-space">
		<ch-blue-button
			[routerLink]="PageUrls.KONTAKT"
			[blurryThing]="true"
			[arrow]="true"
			class="the-button"
		>
			Kontaktujte nás
		</ch-blue-button>
		<div class="button-dots g-dotted-bg"></div>
	</div>

</nav>
