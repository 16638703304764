import { Pipe, PipeTransform } from '@angular/core';
import { nl2br, specChars } from '../../ok-tools';

@Pipe({
	name: 'nl2br',
	pure: true,
})
export class Nl2brPipe implements PipeTransform {

	transform(value: string, htmlChars = true): string {
		let o = value;
		if (htmlChars) {
			o = specChars(o);
		}
		o = nl2br(o);
		return o;
	}

}
