<button class="g-button-nebutton button"
        [class.button--open]="open"
        role="button"
        aria-label="Otevřít menu"
        [class.button--blue]="blue"
>
	<span class="label-space">
		<span class="label label-1">Menu</span>
		<span class="label label-2">Zavřít</span>
	</span>
	<span class="icon-space">
		<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="icon" [class.icon--open]="open">
            <rect x="0" y="3" width="19" height="2" class="line1 shape-animatable"></rect>
            <rect x="0" y="9" width="15" height="2" class="line2 shape-animatable"></rect>
            <rect x="0" y="15" width="19" height="2" class="line3 shape-animatable"></rect>
		</svg>
	</span>
</button>
