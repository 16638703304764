export class DataRetriever<T> {

	protected _data: T;

	protected _promise: Promise<T>;

	protected _received = false;

	constructor(
		protected getterFunction: () => Promise<T>,
	) {
	}

	get data(): Promise<T> {
		if (this._received) {
			return Promise.resolve(this._data);
		} else {
			if (this._promise) {
				return this._promise;
			} else {
				let p = this.getterFunction();
				this._promise = p;
				p.then(
					(d) => {
						this._received = true;
						this._data = d;
					},
				);
				return this._promise;
			}
		}
	}


}
