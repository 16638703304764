import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { BlocksModule } from './blocks/blocks.module';
import { CommonStuffModule } from './common/common-stuff.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhotoswipeModule } from './submodules/ok-angular-tools/modules/photoswipe-module';
import { HttpClientModule } from '@angular/common/http';
import { HtmlHeadTransformToken } from './submodules/ok-angular-tools';
import { HtmlHeadTransform } from './common/html-head-transform';
import { CommonComponentsModule } from './components/common/common-components.module';
import { AppPreloadingStrategy } from './routing/app-preload-strategy';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ScullyLibModule,
		BlocksModule,
		CommonStuffModule,
		BrowserAnimationsModule,
		PhotoswipeModule,
		HttpClientModule,
		CommonComponentsModule,
	],
	providers: [
		{
			provide: HtmlHeadTransformToken,
			useClass: HtmlHeadTransform,
		},
		AppPreloadingStrategy,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
