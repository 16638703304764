import { Injectable } from '@angular/core';
import { ContactLayerComponent } from '../../components/common/contact-layer/contact-layer.component';

@Injectable({
	providedIn: 'root',
})
export class ContactLayerService {

	protected contactLayerComponent: ContactLayerComponent;

	constructor() {

	}

	registerComponent(theComponent: ContactLayerComponent): void {
		if (this.contactLayerComponent) {
			throw new Error('Component is already registered!');
		}
		this.contactLayerComponent = theComponent;
	}

	showContactLayer(): void {
		if (!this.contactLayerComponent) {
			throw new Error('The component is not registered yet.');
		}
		this.contactLayerComponent.open();
	}

}
