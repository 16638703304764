<button class="g-blue-button the-button"
	[class.g-blue-button--with-arrow]="arrow"
    [class.g-blue-button--with-blur]="blurryThing"
    [class.g-blue-button--white]="white"
    [type]="type"
>
	<span class="g-blue-button__content"
	      [class.g-arrow]="arrow"
	      [class.g-arrow--blue]="white && arrow"
	      [class.g-arrow--down]="arrowDown && arrow"
	>
		<ng-content></ng-content>
	</span>
</button>
