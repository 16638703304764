import { factory } from '../submodules/ok-tools';

export interface PhoneContact {
	phone: string;
	tag: string;
	color: string;
}

// tslint:disable-next-line:no-any
export function createPhoneContact(source: any): PhoneContact {
	return factory<PhoneContact>(
		source,
		{
			string: ['phone', 'tag', 'color'],
			from: {
				color: (s) => s.color?.css,
			}
		}
	);
}
