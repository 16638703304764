import { CaseStudyPreview, createCaseStudyPreview } from './case-study-preview';
import { StringWithPseudotags } from './types/string-with-pseudotags';
import { ImageHandle } from '../common/image-url-tools';
import { createReferenceDetail, ReferenceDetail } from './reference-detail';
import { createGlossaryItem, GlossaryItem } from './glossary-item';
import { factory } from '../submodules/ok-tools';
import getYouTubeID from 'get-youtube-id';

export interface CaseStudyDetail extends CaseStudyPreview {

	video?: ImageHandle;
	youtubeIdentifier?: string;
	isYoutubeVideo?: boolean;
	introText: StringWithPseudotags;
	introText2: string;
	reference?: ReferenceDetail;
	glossaryItems?: GlossaryItem[];

}

// tslint:disable-next-line:no-any
export function createCaseStudyDetail(source: any): CaseStudyPreview {

	return {
		...createCaseStudyPreview(source),
		...factory(
			source,
			{
				string: ['introText', 'introText2', 'video', 'youtubeIdentifier'],
				boolean: ['isYoutubeVideo'],
				subItem: {
					reference: createReferenceDetail,
				},
				subItems: {
					glossaryItems: createGlossaryItem,
				},
				from: {
					video: (s) => s.video?.handle,
					isYoutubeVideo: (s) => !!s.youtubeLink,
					youtubeIdentifier: (s) => {
						if (!s.youtubeLink) {
							return '';
						}
						let yt = getYouTubeID(s.youtubeLink);
						if (yt) {
							return yt;
						}
						return '';
					}
				}
			},
		),
	};

}
