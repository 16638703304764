import { RouterLinkStrings } from '../models/types/router-link-strings';
import { PageUrls } from '../routing/page-urls';

export enum TechnologyPageType {
	COV = 'Prumyslove_cistirny_odpadnich_vod',
	PRUMYSLOVE = 'Prumyslove_myci_technologie_na_miru',
	MYCI_LINKY = 'Myci_linky_a_rucni_tlakove_myti',
	MYCI_BOXY = 'Samoobsluzne_a_bezkontaktni_myci_boxy',
	SPECIALNI = 'Specialni_myci_linky',
}

export function technologyTypeHumanName(page: TechnologyPageType): string {
	switch (page) {
		case TechnologyPageType.COV:
			return 'Čistírny odpadních vod';

		case TechnologyPageType.MYCI_BOXY:
			return 'Samoobslužné mycí boxy';

		case TechnologyPageType.MYCI_LINKY:
			return 'Mycí linky';

		case TechnologyPageType.SPECIALNI:
			return 'Speciální mycí linky';

		case TechnologyPageType.PRUMYSLOVE:
			return 'Průmyslové mycí technologie';
	}
}


export function technologyTypeLink(page: TechnologyPageType): RouterLinkStrings {
	switch (page) {
		case TechnologyPageType.COV:
			return PageUrls.TECHNOLOGIE_PRUMYSLOVE_CISTIRNY;

		case TechnologyPageType.MYCI_BOXY:
			return PageUrls.TECHNOLOGIE_SAMOOBSLUZNE_BOXY;

		case TechnologyPageType.MYCI_LINKY:
			return PageUrls.TECHNOLOGIE_MYCI_LINKY;
		case TechnologyPageType.SPECIALNI:
			return PageUrls.TECHNOLOGIE_SPECIALNI_LINKY;

		case TechnologyPageType.PRUMYSLOVE:
			return PageUrls.TECHNOLOGIE_PRUMYSLOVE_MYCI_NA_MIRU;
	}
}
