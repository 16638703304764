import { ImageHandle } from '../common/image-url-tools';

export interface ImageAsset {
	handle: string;
}

export function createImageAssetFromHandle(handle: ImageHandle): ImageAsset {
	if (handle) {
		return {
			handle,
		};
		return null;
	}
}
