import { createDownloadItem, DownloadItem } from './download-item';
import { factory } from '../submodules/ok-tools';

export interface DownloadCategory {
	name: string;
	slug: string;
	id: string;
}

export interface DownloadCategoryWithDownloads extends DownloadCategory {
	downloads: DownloadItem[];
}


// tslint:disable-next-line:no-any
export function createDownloadCategory(source: any): DownloadCategory {
	return factory(
		source,
		{
			string: ['name', 'slug', 'id'],
		},
	);
}

// tslint:disable-next-line:no-any
export function createDownloadCategoryWithDownloads(source: any): DownloadCategoryWithDownloads {
	return {
		...createDownloadCategory(source),
		...factory(
			source,
			{
				subItems: {
					downloads: createDownloadItem,
				}
			}
		)
	};
}
