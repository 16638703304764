import { Component, Input, OnInit } from '@angular/core';
import { UrlString } from '../../../models/types/url-string';

@Component({
	selector: 'ch-yellow-button',
	templateUrl: './yellow-button.component.html',
	styleUrls: ['./yellow-button.component.scss'],
})
export class YellowButtonComponent implements OnInit {

	@Input()
	link: UrlString;

	constructor() {
	}

	ngOnInit(): void {
	}

}
