import { Directive, ElementRef } from '@angular/core';
import { ContactLayerService } from './contact-layer.service';

@Directive({
	selector: '[chShowContactLayer]',
})
export class ShowContactLayerDirective {

	constructor(
		protected theElement: ElementRef,
		protected service: ContactLayerService,
	) {

		this.theElement.nativeElement.addEventListener('click', (event: MouseEvent) => {
			this.service.showContactLayer();
			event.preventDefault();
			event.stopPropagation();
		});

	}

}
