import { factory } from '../submodules/ok-tools';
import { createImageAssetFromHandle, ImageAsset } from './image-asset';
import { StringWithPseudotags } from './types/string-with-pseudotags';

export interface CaseStudyPreview {
	id: string;
	slug: string;
	name: StringWithPseudotags;
	subtitle: string;
	buttonText: string;
	image: ImageAsset;
}

// tslint:disable-next-line:no-any
export function createCaseStudyPreview(source: any): CaseStudyPreview {

	return factory(
		source,
		{
			string: ['id', 'name', 'slug', 'subtitle', 'buttonText'],
			from: {
				image: (s) => createImageAssetFromHandle(s.image?.handle),
			}
		},
	);

}
