<header>
	<ch-top-info-strip
		[data]="topStripData"
		[hiding]="!visibleStrip"
		class="strip"
	></ch-top-info-strip>

	<ch-main-menu
		[fixed]="menuFixed"
		[withStrip]="visibleStrip"
		[animated]="menuAnimated"
		[beforeEnter]="menuBeforeEnter"
		[enter]="menuEnter"
		[stripData]="topStripData"
		[blue]="blue"
	>

	</ch-main-menu>

</header>

