import { Pipe, PipeTransform } from '@angular/core';
import { ImageAsset } from '../models/image-asset';
import { handleToUrl } from './image-url-tools';

@Pipe({
	name: 'src',
	pure: true,
})
export class ImageSrcPipe implements PipeTransform {

	transform(imageAsset: ImageAsset, width: number, nonWebPFormat: string = 'jpg'): string {
		if (!imageAsset?.handle) {
			return '';
		}
		if (width) {
			return handleToUrl(imageAsset.handle, { width }, nonWebPFormat);
		} else {
			return handleToUrl(imageAsset.handle, null, nonWebPFormat);
		}
	}

}
