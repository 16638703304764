<a
	[href]="item.url"
	target="_blank"
	[attr.download]="item.displayInBrowser ? null : item.originalFileName"
	class="box"
	[class.box--no-bg]="!background"
	(click)="clickedItem($event)"
>
	<div class="icon" *ngIf="item.icon">
		<img
			class="the-icon"
			[src]="item.icon | src:0:'svg'"
			[alt]="item.name"
			loading="lazy"
		>
	</div>

	<div class="name">{{ item.name }}</div>

	<div class="details">
		<ng-container *ngIf="item.categoryName && showCategory">{{ item.categoryName }}<br /></ng-container>
		<ng-container *ngIf="item.size > 0">
			<ng-container *ngIf="item.extension">{{ item.extension | uppercase }}, </ng-container>{{ item.size | formatFileSize }}
		</ng-container>
		<ng-container *ngIf="item.isYoutubeLink">
			YouTube video
		</ng-container>
	</div>


</a>
