import { Pipe, PipeTransform } from '@angular/core';
import { ImageAsset } from '../models/image-asset';
import { createResponsiveWidths, handleToUrl } from './image-url-tools';

@Pipe({
	name: 'srcset',
	pure: true,
})
export class ImageSrcsetPipe implements PipeTransform {

	transform(imageAsset: ImageAsset, baseWidth: number, nonWebPFormat: string = 'jpg', addedWidths: number[] = []): string {
		if (!imageAsset?.handle) {
			return '';
		}
		let widths = createResponsiveWidths(baseWidth);
		let parts = [];
		widths = [...addedWidths, ...widths];
		widths.forEach(
			(w) => {
				let url = handleToUrl(imageAsset.handle, { width: w }, nonWebPFormat);
				parts.push(url + ' ' + w + 'w');
			}
		);
		return parts.join(', ');
	}

}
