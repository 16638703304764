import { factory } from '../submodules/ok-tools';
import { createReferenceCompany, ReferenceCompany } from './reference-company';
import { TechnologyPageType } from '../data/technology-page-type';
import { HtmlString } from './types/html-string';
import { createGalleryItem, GalleryItem } from './gallery-item';

export interface ReferenceDetail extends ReferenceCompany {
	technologies: TechnologyPageType[];
	quoteAuthor: string;
	quoteAuthor2: string;
	quoteText: string;
	description: HtmlString;
	description2: HtmlString;
	photos: GalleryItem[];
}

// tslint:disable-next-line:no-any
export function createReferenceDetail(source: any): ReferenceDetail {

	let rc = createReferenceCompany(source);

	return {
		...rc,
		...factory(
			source,
			{
				string: ['quoteAuthor', 'quoteAuthor2', 'quoteText', 'description', 'description2'],
				from: {
					description: (s) => s.description?.html,
					description2: (s) => s.description2?.html,
					technologies: (s) => s.technologyPages?.map(t => t.technology),
					photos: (s) => s.photos?.map(createGalleryItem),
				},
			},
		),
	};

}
