import { Pipe, PipeTransform } from '@angular/core';
import { formatFileSize } from '../../ok-tools';

@Pipe({
	name: 'formatFileSize',
	pure: true,
})
export class FormatFileSizePipe implements PipeTransform {

	transform(value: number): string {
		return formatFileSize(value);
	}

}
