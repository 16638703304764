import { Pipe, PipeTransform } from '@angular/core';
import { stripTags } from '../../ok-tools/src/strip-tags';

@Pipe({
	name: 'stripTags',
	pure: true,
})
export class StripTagsPipe implements PipeTransform {

	transform(value: string): string {
		return stripTags(value);
	}

}
