import { asyncScheduler, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, throttleTime } from 'rxjs/operators';

export interface ScrollClass {
	id: string;
	minScroll?: number;
	maxScroll?: number;
}

export function windowScrollClassWatch(scrollClasses: ScrollClass[]): Observable<ScrollClass | null> {

	return fromEvent(window, 'scroll')
		.pipe(
			throttleTime(
				100,
				asyncScheduler,
				{
					leading: true,
					trailing: true,
				},
			),
			map(
				(event) => {
					let s = window.scrollY;
					if (s < 0) {
						s = 0;
					}
					return s;
				},
			),
			startWith(window.scrollY),
			map(
				(scroll) => scrollClasses.find(
					(c) => (
						(!c.minScroll || c.minScroll <= scroll)
						&&
						(!c.maxScroll || c.maxScroll > scroll)
					),
				),
			),
			distinctUntilChanged(),
		);
}
