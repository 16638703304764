export const CssBreakpoints = {
	Xl: 1620,
	Lg: 1220,
	Md: 1010,
	Sm: 700,
};

export const CssRatios = {
	Md: 0.825,
	Lg: 1,
	Xl: 1.25,
};
