import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { TopInfoStripData } from '../components/common/top-info-strip/top-info-strip-data';
import { HomepageDataModel } from '../models/homepage-data.model';
import { createTechnologyPageDataModel, TechnologyPageDataModel } from '../models/technology-page-data.model';
import { TechnologyPageType } from './technology-page-type';
import { gql } from '../submodules/ok-angular-tools/tools/gql.function';
import { DataRetriever } from './data-retriever';
import { BasicDataModel } from '../models/basic-data.model';
import { createCaseStudyPreview } from '../models/case-study-preview';
import { createReferenceCompany } from '../models/reference-company';
import { createReferenceDetail, ReferenceDetail } from '../models/reference-detail';
import { createDownloadCategoryWithDownloads, DownloadCategoryWithDownloads } from '../models/download-category';
import { CaseStudyDetail, createCaseStudyDetail } from '../models/case-study-detail';
import { createHistoryItem, HistoryItem } from '../models/history-item';
import { createJobOffer, JobOffer } from '../models/job-offer';
import { createLegalPageContent, LegalPageContent } from '../models/legal-page-content';
import { ContactInfo, createContactInfo } from '../models/contact-info';
import { createPersonGroupWithPersons, PersonGroupWithPersons } from '../models/person-group';
import { createGlossaryItemExtended, GlossaryItemExtended } from '../models/glossary-item-extended';

@Injectable({
	providedIn: 'root',
})
export class DataService {

	protected basicDataRetriever: DataRetriever<BasicDataModel>;
	protected homepageDataRetriever: DataRetriever<HomepageDataModel>;
	protected technologyPagesDataRetriever: DataRetriever<{ [key: string]: TechnologyPageDataModel }>;
	protected referencesDataRetriever: DataRetriever<ReferenceDetail[]>;
	protected downloadsDataRetriever: DataRetriever<DownloadCategoryWithDownloads[]>;
	protected caseStudiesRetriever: DataRetriever<CaseStudyDetail[]>;
	protected historyItemsRetriever: DataRetriever<HistoryItem[]>;
	protected jobOffersRetriever: DataRetriever<JobOffer[]>;
	protected personsRetriever: DataRetriever<PersonGroupWithPersons[]>;
	protected glossaryRetriever: DataRetriever<GlossaryItemExtended[]>;

	constructor(
		protected apiService: ApiService,
	) {
		this.basicDataRetriever = new DataRetriever<BasicDataModel>(
			() => {
				return this.basicDataQuery();
			},
		);

		this.homepageDataRetriever = new DataRetriever<HomepageDataModel>(
			() => {
				return this.homepageQuery();
			},
		);

		this.technologyPagesDataRetriever = new DataRetriever<{ [p: string]: TechnologyPageDataModel }>(
			async () => {
				let pagesAsArray = await this.technologyPagesQuery();
				let pagesAsObject = {};
				pagesAsArray.forEach(
					(page) => {
						pagesAsObject[page.type] = page;
					},
				);
				return pagesAsObject;
			},
		);

		this.referencesDataRetriever = new DataRetriever<ReferenceDetail[]>(
			() => this.referenceDetailsQuery(),
		);

		this.downloadsDataRetriever = new DataRetriever<DownloadCategoryWithDownloads[]>(
			() => this.downloadsQuery(),
		);

		this.caseStudiesRetriever = new DataRetriever<CaseStudyDetail[]>(
			() => this.caseStudiesQuery(),
		);

		this.historyItemsRetriever = new DataRetriever<HistoryItem[]>(
			() => this.historyItemsQuery(),
		);

		this.jobOffersRetriever = new DataRetriever<JobOffer[]>(
			() => this.jobOffersQuery(),
		);

		this.personsRetriever = new DataRetriever<PersonGroupWithPersons[]>(
			() => this.personsQuery(),
		);

		this.glossaryRetriever = new DataRetriever<GlossaryItemExtended[]>(
			() => this.glossaryQuery(),
		);
	}

	async getTopInfoStripData(): Promise<TopInfoStripData> {
		let basicData = await this.basicDataRetriever.data;
		return basicData.topInfoStripData;
	}

	async getContactInfo(): Promise<ContactInfo> {
		return (await this.basicDataRetriever.data)?.contactInfo;
	}

	async getHomepageData(): Promise<HomepageDataModel> {
		return this.homepageDataRetriever.data;
	}

	async getTechnologyPageData(page: TechnologyPageType): Promise<TechnologyPageDataModel> {
		return this.technologyPagesDataRetriever.data.then(
			(all) => all[page],
		);
	}

	async getReferences(): Promise<ReferenceDetail[]> {
		return this.referencesDataRetriever.data;
	}

	async getDownloads(): Promise<DownloadCategoryWithDownloads[]> {
		return this.downloadsDataRetriever.data;
	}

	async getCaseStudies(): Promise<CaseStudyDetail[]> {
		return this.caseStudiesRetriever.data;
	}

	async getHistoryItems(): Promise<HistoryItem[]> {
		return this.historyItemsRetriever.data;
	}

	async getJobOffers(): Promise<JobOffer[]> {
		return this.jobOffersRetriever.data;
	}

	async getLegalPageContent(): Promise<LegalPageContent[]> {
		return (await this.basicDataRetriever.data).legalPages;
	}

	async getPersons(): Promise<PersonGroupWithPersons[]> {
		return this.personsRetriever.data;
	}

	async getGlossaryItemExtended(slug: string): Promise<GlossaryItemExtended> {
		return (await this.glossaryRetriever.data).find((s) => s.slug === slug);
	}

	async getGlossary(): Promise<GlossaryItemExtended[]> {
		return this.glossaryRetriever.data;
	}

	protected async basicDataQuery(): Promise<BasicDataModel> {
		let out = await this.apiService.call(gql`
				query BasicData {
					topBars(first: 1) {
						text {
							html
						}
						yellowLink
					}
					contactInfos(first: 1) {
						email
						phoneContacts {
							color {
								css
							}
							phone
							tag
						}
						officeHours
						address
						ico
						dic
						registration
						mapLink
						photoHQ {
							handle
						}
						mapHQ {
							handle
						}
					}
					legalPages {
						heading
						slug
						metaDescription
						shortHeading
						content {
							html
						}
					}
				}
			`,
			{},
			'BasicData',
		);
		let topBar = out.topBars[0];
		return {
			topInfoStripData: {
				link: topBar.yellowLink,
				text: topBar.text.html,
			},
			contactInfo: createContactInfo(out.contactInfos[0]),
			legalPages: out.legalPages.map(createLegalPageContent),
		};
	}

	protected async homepageQuery(): Promise<HomepageDataModel> {
		let out = await this.apiService.call(gql`
			query HomePageData {
				caseStudies(where: {showOnHomepage: true}, orderBy: priority_DESC) {
					name
					subtitle
					image {
						handle
					}
					slug
					buttonText
					id
				}
				references(where: {showOnHomepage: true}, orderBy: priority_DESC) {
					company
					slug
					logo {
						handle
					}
				}
			}
		`, {}, 'HomepageData');

		out.caseStudies = out.caseStudies.map(createCaseStudyPreview);

		out.references = out.references.map(createReferenceCompany);

		return out;

	}

	protected async technologyPagesQuery(): Promise<TechnologyPageDataModel[]> {
		let out = await this.apiService.call(gql`
			query TechnologyPages {
				technologyPages {
					technology
					downloads {
						file {
							handle
							size
							url
							fileName,
						}
						youtubeLink
						displayInBrowser
						name
						icon {
							handle
						}
					}
					caseStudies {
						name
						subtitle
						image {
							handle
						}
						slug
						buttonText
						id
					}
					references {
						company
						slug
						logo {
							handle
						}
					}
					glossaryItems(where: { showOnTechnologyPage: true}) {
						id
						slug
						name
						shortDescription
					}
					photogallery {
						handle
						width
						height
					}
				}
			}

		`, {}, 'TechnologyPagesData');

		let pages = out.technologyPages.map(createTechnologyPageDataModel);

		return pages;

	}

	protected async referenceDetailsQuery(): Promise<ReferenceDetail[]> {
		let out = await this.apiService.call(gql`
			query References {
				references(orderBy: priority_DESC) {
					id
					slug
					name
					company
					description {
						html
					}
					description2 {
						html
					}
					technologyPages {
						technology
					}
					logo {
						handle
					}
					quoteAuthor
					quoteAuthor2
					quoteText
					photos {
						width
						handle
						height
					}
				}
			}

		`, {}, 'References');

		let references = out.references.map(createReferenceDetail);

		return references;
	}

	protected async downloadsQuery(): Promise<DownloadCategoryWithDownloads[]> {
		let out = await this.apiService.call(gql`
			query DownloadCategoriesWithDownloads {
				downloadCategories(orderBy: priority_DESC) {
					name
					slug
					id
					downloads {
						name
						displayInBrowser
						youtubeLink
						icon {
							handle
						}
						file {
							size
							url
							fileName
							handle
						}
					}
				}
			}
		`, {}, 'DownloadCategories');
		return out.downloadCategories.map(createDownloadCategoryWithDownloads);
	}

	protected async caseStudiesQuery(): Promise<CaseStudyDetail[]> {
		let out = await this.apiService.call(gql`
			query CaseStudies {
				caseStudies(orderBy: priority_DESC) {
					name
					subtitle
					image {
						handle
					}
					slug
					buttonText
					id
					video {
						handle
					}
					youtubeLink,
					introText,
					introText2,
					reference {
						id
						slug
						name
						company
						logo {
							handle
						}
						quoteAuthor
						quoteAuthor2
						quoteText
					}
					glossaryItems {
						id
						slug
						name
						shortDescription
					}
				}
			}
		`, {}, 'CaseStudies');

		return out.caseStudies.map(createCaseStudyDetail);
	}

	protected async historyItemsQuery(): Promise<HistoryItem[]> {
		return (await this.apiService.call(
			gql`
				query HistoryItems {
					historyDates(orderBy: year_ASC) {
						year
						description
					}
				}
			`,
			null,
			'HistoryItems',
		)).historyDates.map(createHistoryItem);
	}

	protected async jobOffersQuery(): Promise<JobOffer[]> {
		return (await this.apiService.call(
			gql`
				query JobOffers {
					jobOffers(orderBy: sort_DESC) {
						name
						description {
							html
						}
						subtitle
						id
						attachments {
							size
							url
							fileName
							description
						}
					}
				}
			`,
			null,
			'JobOffers',
		)).jobOffers.map(createJobOffer);
	}

	protected async personsQuery(): Promise<PersonGroupWithPersons[]> {
		return (await this.apiService.call(
			gql`
				query Persons {
					personGroups(orderBy: sort_DESC) {
						name
						note
						displayType
						persons {
							name
							phone
							email
							position
							photo {
								handle
							}
						}
					}
				}
			`,
			null,
			'Persons',
		)).personGroups.map(createPersonGroupWithPersons);
	}

	protected async glossaryQuery(): Promise<GlossaryItemExtended[]> {
		return (await this.apiService.call(
			gql`
				query GlossaryItems {
					glossaryItems(orderBy: name_ASC) {
						id
						name
						slug
						shortDescription
						longDescription {
							html
						}
						technologyPages {
							technology
						}
						photos {
							handle
							width
							height
							description
						}
						downloads {
							name
							displayInBrowser
							youtubeLink
							icon {
								handle
							}
							file {
								size
								url
								fileName
								handle
							}
						}
						seeAlso {
							slug
							name
							id
						}
					}
				}
			`,
			null,
			'Glossary',
		)).glossaryItems.map(createGlossaryItemExtended);
	}


}
