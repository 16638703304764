import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { PhotoswipeService } from './photoswipe-service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'ok-photoswipe-element-component',
	styleUrls: ['photoswipe-element.scss'],
	template: `
		<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true" #el>
			<div class="pswp__bg"></div>
			<div class="pswp__scroll-wrap">

				<div class="pswp__container">
					<div class="pswp__item"></div>
					<div class="pswp__item"></div>
					<div class="pswp__item"></div>
				</div>

				<div class="pswp__ui pswp__ui--hidden">

					<div class="pswp__top-bar">

						<div class="pswp__counter"></div>

						<button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

						<div class="pswp__preloader">
							<div class="pswp__preloader__icn">
								<div class="pswp__preloader__cut">
									<div class="pswp__preloader__donut"></div>
								</div>
							</div>
						</div>
					</div>

					<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
					</button>

					<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
					</button>

					<div class="pswp__caption">
						<div class="pswp__caption__center"></div>
					</div>

				</div>

			</div>

		</div>
	`,
})
export class PhotoswipeElementComponent implements AfterViewInit {

	@ViewChild('el')
	public el: ElementRef;

	constructor(
		protected service: PhotoswipeService,
	) {

	}

	ngAfterViewInit(): void {
		this.service.setElement(this.el.nativeElement);
	}


}
