import { createImageAssetFromHandle, ImageAsset } from './image-asset';
import { factory } from '../submodules/ok-tools';

export interface Person {

	name: string;
	position: string;
	photo: ImageAsset;
	phone: string;
	email: string;

}


// tslint:disable-next-line:no-any
export function createPerson(source: any): Person {

	return factory<Person>(
		source,
		{
			string: ['name', 'position', 'phone', 'email'],
			from: {
				photo: (s) => createImageAssetFromHandle(s.photo?.handle),
			},
		},
	);

}
