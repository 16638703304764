import { factory } from '../submodules/ok-tools';
import { createImageAssetFromHandle, ImageAsset } from './image-asset';

export interface ReferenceCompany {
	id: string;
	slug: string;
	name: string;
	company: string;
	logo: ImageAsset;
}

// tslint:disable-next-line:no-any
export function createReferenceCompany(source: any): ReferenceCompany {

	return factory(
		source,
		{
			string: ['id', 'name', 'slug', 'company'],
			from: {
				logo: (s) => createImageAssetFromHandle(s.logo?.handle),
			}
		},
	);

}
