import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ch-blue-button',
	templateUrl: './blue-button.component.html',
	styleUrls: ['./blue-button.component.scss'],
})
export class BlueButtonComponent implements OnInit {

	@Input()
	public arrow = false;

	@Input()
	public arrowDown = false;

	@Input()
	public blurryThing = false;

	@Input()
	public white = false;

	@Input()
	public type = 'button';

	constructor() {
	}

	ngOnInit(): void {
	}

}
