<div
	class="overlay"
	(click)="hide()"
	[class.overlay--visible]="visible"
>

</div>

<div
	class="body"
	[class.body--visible]="visible"
	#bodyElement
	(click)="hide()"
>

	<div class="body-content">
		<button type="button" class="closer g-button-nebutton" (click)="hide()">
			<img src="/assets/imgs/close-megamenu.svg" alt="Zavřít" />
		</button>

		<div class="g-container" (click)="$event.stopPropagation();">

			<div class="dots g-dotted-bg"></div>

			<div class="input-box">
				<img src="/assets/imgs/search-icon-blue.svg" alt="Hledat!" class="input-icon" *ngIf="!loading" />
				<div class="loading-indicator la-ball-clip-rotate-pulse la-2x" *ngIf="loading">
					<div></div>
					<div></div>
				</div>

				<div class="megainput-space">
					<input class="megainput"
					       placeholder="zadejte hledaný výraz"
					       type="search"
					       #theInput
					       [(ngModel)]="searchString"
					       (input)="fieldValueChanged()"
					/>
					<div class="small-subtitle" *ngIf="searchString.length >= 1 && searchString.length < 3">
						Zadejte alespoň tři znaky...
					</div>
					<div *ngIf="loading" class="small-subtitle">
						Hledám „{{ searchString }}“
					</div>
					<ng-container *ngIf="!loading">
						<div class="small-subtitle" *ngIf="result?.totalItemsCount">
							{{ result?.totalItemsCount | plural:'Nalezen %% výskyt':'Nalezeny %% výskyty':'Nalezeno %% výskytů' }} „{{ result.searchTerm }}“
						</div>
					</ng-container>
					<div class="big-subtitle" *ngIf="result?.totalItemsCount === 0">
						Nic nebylo nalezeno.
					</div>

				</div>
			</div>

			<div class="results">

				<div class="result-type-block" *ngIf="result?.technologies.count">

					<h2 class="result-type-heading">Technologie</h2>

					<ch-search-result-standard
						*ngFor="let item of result.technologies.items"
						[title]="item.name"
						[subtitle]="item.subtext"
						[image]="item.image"
						[link]="item.link"
					></ch-search-result-standard>

					<div class="result-type-dots g-dotted-bg"></div>

				</div>

				<div class="result-type-block" *ngIf="result?.person.count">

					<h2 class="result-type-heading">Kontakty</h2>

					<div class="result-small-grid">
						<ch-search-result-small
							*ngFor="let item of result.person.items"
							[title]="item.name"
							[subtitle]="item.email"
							[blueSubtitle]="item.phone | phoneNumber:false:' '"
							[subtitleLink]="'mailto:' + item.email"
							[blueSubtitleLink]="'tel:' + (item.phone | phoneNumber)"
							[image]="item.photo"
							[link]="PageUrls.KONTAKT"
							[roundImage]="true"
						></ch-search-result-small>
					</div>


					<div class="result-type-dots g-dotted-bg"></div>

				</div>


				<div class="result-type-block" *ngIf="result?.downloads.count">

					<h2 class="result-type-heading">Ke stažení</h2>


					<div class="results-big-grid">
						<ch-downloads-grid-item
							class="result-download-item"
							*ngFor="let item of result.downloads.items"
							[item]="item"
							[background]="false"
							[showCategory]="true"
							(openVideoPopup)="openYoutube(item)"
						></ch-downloads-grid-item>
					</div>

					<div class="result-type-dots g-dotted-bg"></div>

				</div>


				<div class="result-type-block" *ngIf="result?.caseStudies.count">

					<h2 class="result-type-heading">Případové studie</h2>

					<ch-search-result-standard
						*ngFor="let item of result.caseStudies.items"
						[title]="item.name"
						[subtitle]="item.subtitle"
						[image]="item.image"
						[link]="PageUrls.RESENI | addToRouterLink:[item.slug]"
					></ch-search-result-standard>

					<div class="result-type-dots g-dotted-bg"></div>

				</div>

				<div class="result-type-block" *ngIf="result?.references.count">

					<h2 class="result-type-heading">Reference</h2>

					<div class="result-small-grid">

						<ch-search-result-small
							*ngFor="let item of result.references.items"
							[title]="item.company"
							[subtitle]="item.name"
							[image]="item.logo"
							[link]="PageUrls.REFERENCE | addToRouterLink:[item.slug]"
						></ch-search-result-small>

					</div>

					<div class="result-type-dots g-dotted-bg"></div>

				</div>

				<div class="result-type-block" *ngIf="result?.glossary.count">

					<h2 class="result-type-heading">Slovník pojmů</h2>

					<div class="result-small-grid">
						<ch-search-result-small
							*ngFor="let item of result.glossary.items"
							[title]="item.name"
							[subtitle]="item.shortDescription"
							[link]="PageUrls.SLOVNIK_POJMU | addToRouterLink:[item.slug]"
						></ch-search-result-small>
					</div>

					<div class="result-type-dots g-dotted-bg"></div>

				</div>

			</div>

			<div class="more-link">
				<ch-text-link-with-arrow
					(click)="showAllItemsButtonClicked()"
					*ngIf="showMoreLink"
				>
					Zobrazit všechny výsledky
				</ch-text-link-with-arrow>
			</div>


		</div>
	</div>


</div>

