import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { createScrollWatch, ScrollState } from './stuff/scroll-state';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { TopInfoStripData } from '../../components/common/top-info-strip/top-info-strip-data';
import { DataService } from '../../data/data.service';

@Component({
	selector: 'ch-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {

	public scrollState$: Observable<ScrollState>;

	public visibleStrip = true;
	public didSeeStripAtLeastOnce = false;

	public menuAnimated = false;
	public menuFixed = false;
	public menuBeforeEnter = false;
	public menuEnter = false;
	public menuLeavingTimeout = null;

	public sink = new SubSink();

	@Input()
	blue = false;

	public topStripData: TopInfoStripData = {
		link: '',
		text: '',
	};

	constructor(
		protected dataService: DataService,
	) {
	}

	ngOnInit(): void {
		this.topStripData = { link: '', text: '' };
		this.dataService.getTopInfoStripData().then(
			(d) => { this.topStripData = d; }
		);
		this.scrollState$ = createScrollWatch();
		this.sink.sink = this.scrollState$.subscribe(
			(up) => {
				// Strip
				if (up === 'up' && !this.didSeeStripAtLeastOnce) {
					this.didSeeStripAtLeastOnce = true;
				}
				if (up === 'down' && this.didSeeStripAtLeastOnce) {
					this.visibleStrip = false;
				}

				// Menu
				if (up === 'down' && !this.menuFixed) {
					this.menuFixed = true;
					this.menuAnimated = false;
					this.menuBeforeEnter = true;
					this.menuEnter = false;
					setTimeout(
						() => {
							this.menuAnimated = true;
							this.menuEnter = true;
						},
						50,
					);
					if (this.menuLeavingTimeout) {
						clearTimeout(this.menuLeavingTimeout);
						this.menuLeavingTimeout = null;
					}
				}

				if (up === 'up') {
					this.menuFixed = false;
					this.menuAnimated = false;
					this.menuBeforeEnter = false;
					this.menuEnter = false;
					if (this.menuLeavingTimeout) {
						clearTimeout(this.menuLeavingTimeout);
						this.menuLeavingTimeout = null;
					}
				}

				if (up === 'almost-up') {
					if (this.menuFixed) {
						this.menuAnimated = true;
						this.menuBeforeEnter = true;
						this.menuEnter = false;
						this.menuLeavingTimeout = setTimeout(
							() => {
								this.menuFixed = false;
								this.menuAnimated = false;
								this.menuEnter = false;
								this.menuBeforeEnter = false;
							},
							400,
						);
					}
				}
			},
		);
	}

	ngOnDestroy(): void {
		this.sink.unsubscribe();
	}

}
