import { Component, Input, OnInit } from '@angular/core';
import { MegaMenuItems } from '../../../routing/menu-items';
import { PageUrls } from '../../../routing/page-urls';

@Component({
	selector: 'ch-mega-menu',
	templateUrl: './mega-menu.component.html',
	styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent implements OnInit {

	public items = MegaMenuItems;

	public PageUrls = PageUrls;

	@Input()
	visible = true;

	constructor() {
	}

	ngOnInit(): void {
	}

}
