import { PageUrls } from './page-urls';
import { RouterLinkStrings } from '../models/types/router-link-strings';
import { ImageAsset } from '../models/image-asset';
import { TechnologyPageType } from '../data/technology-page-type';

export interface MainMenuItem {
	text: string;
	expandable?: boolean;
	link?: RouterLinkStrings;
}

export interface MegaMenuItem {
	link: RouterLinkStrings;
	image: ImageAsset;
	name: string;
	shorterName: string;
	subtext: string;
	technologyPageType: TechnologyPageType;
}


export const MegaMenuItems: MegaMenuItem[] = [
	{
		name: 'Průmyslové čistírny odpadních vod',
		shorterName: 'Průmyslové ČOV',
		link: PageUrls.TECHNOLOGIE_PRUMYSLOVE_CISTIRNY,
		image: {
			handle: 'PVk2cC9iSR2r9IbYtbHW',
		},
		subtext: 'Čistírny odpadních vod dodáváme v rámci projektů a realizací na míru.',
		technologyPageType: TechnologyPageType.COV,
	},
	{
		name: 'Průmyslové mycí technologie na míru',
		shorterName: 'Průmyslové realizace',
		link: PageUrls.TECHNOLOGIE_PRUMYSLOVE_MYCI_NA_MIRU,
		image: {
			handle: 'kB1BYihuRVmTKsN1JFJk',
		},
		subtext: 'Navrhujeme a realizujeme mycí technologie do provozů, kde jsou myčky součástí výrobního procesu. ',
		technologyPageType: TechnologyPageType.PRUMYSLOVE,
	},
	{
		name: 'Mycí linky a ruční tlakové mytí',
		shorterName: 'Mycí linky a ruční tlakové mytí',
		link: PageUrls.TECHNOLOGIE_MYCI_LINKY,
		image: {
			handle: '6AfijHeQT36u1AyQcH3e',
		},
		subtext: 'Specializujeme se na portálové kartáčové mycí linky a tlakové ruční mytí pro osobní a nákladní vozy. Všechny navrhujeme na míru konkrétnímu projektu.',
		technologyPageType: TechnologyPageType.MYCI_LINKY,
	},
	{
		name: 'Samoobslužné a bezkontaktní mycí boxy',
		shorterName: 'Samoobslužné mycí boxy',
		link: PageUrls.TECHNOLOGIE_SAMOOBSLUZNE_BOXY,
		image: {
			handle: 'Q1jfRISBSP6G73BUJHpu',
		},
		subtext: 'Snadné ruční mytí osobních aut a dodávek. Rychlé ruční mytí určené pro veřejnost nebo v rámci podnikání.',
		technologyPageType: TechnologyPageType.MYCI_BOXY,
	},
	{
		name: 'Speciální mycí linky',
		shorterName: 'Speciální mycí linky',
		link: PageUrls.TECHNOLOGIE_SPECIALNI_LINKY,
		image: {
			handle: 'bHiJCXmHTPa2KBSgf1IA',
		},
		subtext: 'Pro techniku, která je natolik specifická, že jí nestačí sériové řešení.',
		technologyPageType: TechnologyPageType.SPECIALNI,
	},
];

export const MegaMenuItemsWithAnotherSorting = [
	MegaMenuItems[1],
	MegaMenuItems[0],
	...MegaMenuItems.slice(2),
];

export const MainMenuItems: MainMenuItem[] = [
	{
		text: 'Řešení',
		link: PageUrls.RESENI,
	},
	{
		text: 'Reference',
		link: PageUrls.REFERENCE,
	},
	{
		text: 'O nás',
		link: PageUrls.O_NAS,
	},
	{
		text: 'Kariéra',
		link: PageUrls.KARIERA,
	},
	{
		text: 'Kontakt',
		link: PageUrls.KONTAKT,
	},
];
