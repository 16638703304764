import { Component, Input, OnInit } from '@angular/core';
import { RouterLinkStrings } from '../../../models/types/router-link-strings';

@Component({
	selector: 'ch-text-link-with-arrow',
	templateUrl: './text-link-with-arrow.component.html',
	styleUrls: ['./text-link-with-arrow.component.scss'],
})
export class TextLinkWithArrowComponent implements OnInit {

	@Input()
	link: RouterLinkStrings;

	@Input()
	active: boolean;

	constructor() {
	}

	ngOnInit(): void {
	}

}
