import { Pipe, PipeTransform } from '@angular/core';
import { phoneNumberFormatter } from '../../ok-tools';

@Pipe({
	name: 'phoneNumber',
	pure: true,
})
export class PhoneNumberFormatPipe implements PipeTransform {

	transform(
		input: string,
		international = true,
		spaces = '',
		internationalPrefix = '+',
		defaultInternational = '420',
	): string {
		return phoneNumberFormatter(input, international, spaces, internationalPrefix, defaultInternational);
	}

}
