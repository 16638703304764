export enum ContactFormType {
	COV = 'cov',
	MYCI_LINKA = 'myci-linka',
	MYCI_BOX = 'myci-box',
	SPECIALNI_MYTI = 'specialni-myti',
	JINY = 'jiny',
}

export const ContactFormTypeHumanNames: {[key in ContactFormType]: string} = {
	[ContactFormType.COV]: 'Čistírna odpadních vod',
	[ContactFormType.MYCI_BOX]: 'Mycí box',
	[ContactFormType.MYCI_LINKA]: 'Mycí linka',
	[ContactFormType.SPECIALNI_MYTI]: 'Speciální mytí',
	[ContactFormType.JINY]: 'Obecný dotaz nebo jiná poptávka',
};
