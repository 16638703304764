import { UrlString } from './types/url-string';
import { createImageAssetFromHandle, ImageAsset } from './image-asset';
import { factory } from '../submodules/ok-tools';
import getYouTubeID from 'get-youtube-id';

export interface DownloadItem {
	name: string;
	extension: string;
	size: number;
	url: UrlString;
	displayInBrowser: boolean;
	icon: ImageAsset;
	isYoutubeLink: boolean;
	originalFileName: string;
	categoryName?: string;
}

// tslint:disable-next-line:no-any
export function createDownloadItem(source: any): DownloadItem {
	return factory(
		source,
		{
			string: ['name', 'url', 'extension', 'originalFileName', 'categoryName'],
			number: ['size'],
			boolean: ['displayInBrowser', 'isYoutubeLink'],
			default: {
				displayInBrowser: false,
			},
			from: {
				size: (s) => s.file?.size,
				isYoutubeLink: (s) => !!s.youtubeLink,
				url: (s) => {
					if (s.youtubeLink) {
						let id = getYouTubeID(s.youtubeLink);
						if (id) {
							return 'https://www.youtube.com/embed/' + id + '?autoplay=1&rel=0';
						}
					}
					return s.file?.url;
				},
				originalFileName: (s) => (s.file?.fileName),
				icon: (s) => createImageAssetFromHandle(s.icon?.handle),
				categoryName: (s) => (s.downloadCategory?.name),
				extension: (s) => {
					let name = s.file?.fileName;
					if (!name) {
						return '';
					}
					let match = name.match(/\.([a-z0-9]{1,10})$/);
					if (match) {
						return match[1].toUpperCase();
					}
					return '';
				},
			},
		},
	);
}
