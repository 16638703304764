import { createGlossaryItem, GlossaryItem } from './glossary-item';
import { HtmlString } from './types/html-string';
import { createGalleryItem, GalleryItem } from './gallery-item';
import { createDownloadItem, DownloadItem } from './download-item';
import { TechnologyPageType } from '../data/technology-page-type';
import { factory, string } from '../submodules/ok-tools';

export interface GlossaryItemExtended extends GlossaryItem {
	technologies: TechnologyPageType[];
	longDescription: HtmlString;
	photos: GalleryItem[];
	downloads: DownloadItem[];
	seeAlso: GlossaryItem[];
}

// tslint:disable-next-line:no-any
export function createGlossaryItemExtended(source: any): GlossaryItemExtended {

	return {
		...createGlossaryItem(source),
		...factory(
			source,
			{
				string: ['longDescription'],
				subItems: {
					technologies: (s) => string(s.technology),
					seeAlso: createGlossaryItem,
					photos: createGalleryItem,
					downloads: createDownloadItem,
				},
				from: {
					longDescription: (s) => s.longDescription?.html,
					technologies: (s) => s.technologyPages,
				}
			}
		)
	};

}
