import { Pipe, PipeTransform } from '@angular/core';
import { CssBreakpoints, CssRatios } from './css-variables';

@Pipe({
	name: 'sizes',
	pure: true,
})
export class ImageSizesPipe implements PipeTransform {

	transform(width: number, mobileVersion = '', tabletVersion = ''): string {
		let parts = [];

		if (mobileVersion && !tabletVersion) {
			parts.push('(max-width: ' + CssBreakpoints.Md + 'px) ' + mobileVersion);
		}

		if (mobileVersion && tabletVersion) {
			parts.push('(max-width: ' + CssBreakpoints.Sm + 'px) ' + mobileVersion);
		}

		if (tabletVersion) {
			parts.push('(min-width: ' + CssBreakpoints.Sm + 'px) and (max-width: ' + CssBreakpoints.Md + 'px) ' + tabletVersion);
		}

		parts.push('(min-width: ' + CssBreakpoints.Md + 'px) and (max-width: ' + CssBreakpoints.Lg + 'px) ' + Math.round(CssRatios.Md * width) + 'px');
		parts.push('(min-width: ' + CssBreakpoints.Lg + 'px) and (max-width: ' + CssBreakpoints.Xl + 'px) ' + Math.round(CssRatios.Lg * width) + 'px');
		parts.push('(min-width: ' + CssBreakpoints.Xl + 'px) ' + Math.round(CssRatios.Xl * width) + 'px');

		return parts.join(', ');
	}

}
