import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiService {

	private API_URL = environment.graphCmsApiUrl;

	private PHP_API_URL = environment.phpApiUrl;

	constructor(
		protected http: HttpClient,
	) {

	}

	// tslint:disable-next-line:no-any
	public call(query: string, variables: any = null, label = ''): Promise<any> {
		return this.http.post(
			this.API_URL + (label ? ('?label=' + label) : ''),
			{
				query,
				variables,
			},
		).toPromise().then(
			// tslint:disable-next-line:no-any
			(r: any) => r.data,
		);
	}

	// tslint:disable-next-line:no-any
	public callPhpApi(endpoint: string, data: any, method = 'post'): Promise<string> {
		let url = this.PHP_API_URL + '/' + endpoint;
		let request = new HttpRequest(method, url, data);
		return this.http.request(
			request,
		).toPromise().then(
			(response: HttpResponse<string>) => {
				return response.body;
			}
		);
	}

}
