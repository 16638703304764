import { factory, number, string } from '../submodules/ok-tools';
import { HtmlString } from './types/html-string';

export interface JobOffer {
	name: string;
	id: string;
	subtitle: string;
	description: HtmlString;
	attachments: { filesize: number, name: string, extension: string, url: string }[];
}

// tslint:disable-next-line:no-any
export function createJobOffer(source: any): JobOffer {
	return factory<JobOffer>(
		source,
		{
			string: ['name', 'description', 'id', 'subtitle'],
			from: {
				description: (s) => {
					let d: string = s.description?.html || '';
					if (d.length > 10) {
						return d;
					}
					return '';
				},
			},
			subItems: {
				attachments: (s) => {

					let name = s.fileName;
					let basename = '';
					let extension = '';
					let match = name.match(/^(.*)\.([a-z0-9]{1,10})$/);
					if (match) {
						basename = match[1];
						extension = match[2];
					}

					return {
						filesize: number(s.filesize),
						name: string(s?.description || basename),
						extension: string(extension),
						url: s.url,
					};
				},
			},
		},
	);
}
