import { Pipe, PipeTransform } from '@angular/core';
import { numberFormat } from '../../ok-tools';

@Pipe({
	name: 'numberFormat',
	pure: true,
})
export class NumberFormatPipe implements PipeTransform {

	transform(value: string | number, decimals = 0, decPoint = '.', thousandsSep = ''): string {
		return numberFormat(value, decimals, decPoint, thousandsSep);
	}

}
