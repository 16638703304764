import { UrlString } from '../models/types/url-string';
import { supportsWebP } from '../submodules/ok-angular-tools/tools/webp-support-detector';
import { CssRatios } from './css-variables';

export type ImageHandle = string;

export interface ImageTransformation {
	width?: number;
	height?: number;
	fit?: 'clip' | 'crop' | 'scale' | 'max';
}

export function handleToUrl(handle: ImageHandle, transformation: ImageTransformation, nonWebpFormat = 'jpg'): UrlString {
	let transformationQuery = '';
	let formatQuery = '';
	if (transformation) {
		let parts = [];
		if (transformation.fit) {
			parts.push('fit:' + transformation.fit);
		}
		if (transformation.width) {
			parts.push('width:' + transformation.width);
		}
		if (transformation.height) {
			parts.push('height:' + transformation.height);
		}
		if (parts.length > 0) {
			transformationQuery = 'resize=' + parts.join(',') + '/';
		}
	}
	if (nonWebpFormat && nonWebpFormat !== 'svg') {
		if (supportsWebP()) {
			formatQuery = 'output=format:webp/';
		} else {
			formatQuery = 'output=format:jpg/';
		}
	}
	return 'https://media.graphassets.com/' + transformationQuery + formatQuery + handle;
}


export function createResponsiveWidths(baseWidth: number): number[] {
	return [
		Math.round(CssRatios.Md * baseWidth),
		Math.round(CssRatios.Lg * baseWidth),
		Math.round(CssRatios.Xl * baseWidth),
		Math.round(CssRatios.Md * baseWidth * 2),
		Math.round(CssRatios.Lg * baseWidth * 2),
		Math.round(CssRatios.Xl * baseWidth * 2),
	];
}

export function createMultipleResponsiveWidths(baseWidths: number[]): number[] {
	let b = baseWidths.map(createResponsiveWidths);
	let w = [];
	b.forEach((widths) => w = [...w, ...widths]);
	w.sort((a1, a2) => (a1 - a2));
	return w;
}
