<a class="box" [routerLink]="link">
	<div class="image-part" *ngIf="image">
		<img
			[alt]="title"
			[src]="image | src:80"
			[srcset]="image | srcset:80"
			[sizes]="80 | sizes"
			class="image"
			[class.image--round]="roundImage"
		/>
	</div>
	<div class="text-part">
		<h3 class="name" [innerHTML]=" title | pseudoTags ">

		</h3>
		<div class="blue-subtitle" [class.blue-subtitle--clickable]="blueSubtitleLink?.length" (click)="clickedBlueSubtitle($event)">
			{{ blueSubtitle }}
		</div>
		<div class="subtitle" [class.subtitle--clickable]="subtitleLink?.length" (click)="clickedSubtitle($event)">
			{{ subtitle }}
		</div>
	</div>
</a>
