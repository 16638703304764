<ng-container *ngIf="link">
	<a class="box link" [href]="link" [style.--color]="color" [class.box--lg-margin]="marginBottom === 'lg'">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>
</ng-container>

<ng-container *ngIf="!link">
	<div class="box" [style.--color]="color" [class.box--lg-margin]="marginBottom === 'lg'">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</div>
</ng-container>




<ng-template #content>


	<div class="icon-part" [class.icon-part--has-tag]="tag">

		<div class="icon-wrap" [class.icon-wrap--bg]="addBgToIcon" *ngIf="iconSrc">
			<img [src]="iconSrc" [alt]="text | stripTags" class="icon-img" />
		</div>

	</div>

	<div class="text-part" [class.text-part--has-tag]="tag">

		<div class="tag" *ngIf="tag">{{ tag }}</div>

		<div class="text" *ngIf="text">{{ text }}</div>

		<div class="subtext" *ngIf="subText">{{ subText }}</div>

	</div>

</ng-template>
