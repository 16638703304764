import { createPhoneContact, PhoneContact } from './phone-contact';
import { createImageAssetFromHandle, ImageAsset } from './image-asset';
import { factory } from '../submodules/ok-tools';

export interface ContactInfo {
	email: string;
	phoneContacts: PhoneContact[];
	officeHours: string;
	address: string;
	ico: string;
	dic: string;
	registration: string;
	mapLink: string;
	photoHQ: ImageAsset;
	mapHQ: ImageAsset;
}


// tslint:disable-next-line:no-any
export function createContactInfo(source: any): ContactInfo {
	return factory<ContactInfo>(
		source, {
			string: ['email', 'officeHours', 'address', 'ico', 'dic', 'registration', 'mapLink'],
			subItems: {
				phoneContacts: createPhoneContact,
			},
			from: {
				photoHQ: (s) => createImageAssetFromHandle(s.photoHQ?.handle),
				mapHQ: (s) => createImageAssetFromHandle(s.mapHQ?.handle),
			}
		}
	);
}
