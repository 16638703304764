import { createImageAssetFromHandle, ImageAsset } from './image-asset';
import { factory } from '../submodules/ok-tools';

export interface GalleryItem {
	image: ImageAsset;
	width: number;
	height: number;
	text: string;
}

// tslint:disable-next-line:no-any
export function createGalleryItem(source: any): GalleryItem {
	return factory<GalleryItem>(
		source,
		{
			number: ['width', 'height'],
			string: ['text'],
			from: {
				image: (s) => createImageAssetFromHandle(s.handle),
			}
		}
	);
}
