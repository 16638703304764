import { factory } from '../submodules/ok-tools';

export interface HistoryItem {
	year: number;
	description: string;
}

// tslint:disable-next-line:no-any
export function createHistoryItem(source: any): HistoryItem {
	return factory<HistoryItem>(
		source,
		{
			number: ['year'],
			string: ['description'],
		}
	);
}
