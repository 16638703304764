<div class="contact-layer" *ngIf="show" [class.contact-layer--hidden]="!showInner">

	<div class="pos" #position>



		<div class="overlay overlay--white overlay--left">

			<div class="overlay-content" *ngIf="contact">

				<div class="logo-space">
					<img src="/assets/imgs/logo.svg" alt="Logo ChemieStar" class="logo" />
				</div>

				<div class="contact-items">

					<ch-contact-item
						*ngFor="let phone of contact.phoneContacts"
						[tag]="phone.tag"
						[link]="'tel:' + (phone.phone | phoneNumber:true:'')"
						[color]="phone.color"
						[text]="phone.phone | phoneNumber:true:' '"
						iconSrc="/assets/imgs/phone-icon.svg"
						[addBgToIcon]="true"
					>
					</ch-contact-item>

					<ch-contact-item
						[text]="contact.email"
						[link]="'mailto:' + contact.email"
						iconSrc="/assets/imgs/email-icon-with-bg.svg"
						[addBgToIcon]="false"
					></ch-contact-item>

				</div>


				<div class="address">
					<div class="address-heading">Kde nás najdete</div>
					<div [innerHTML]="contact.address | nl2br">
					</div>
				</div>

				<div class="legal-note">
					{{ contact.registration }}
				</div>

				<div class="more-link">
					<ch-blue-button
						[routerLink]="PageUrls.KONTAKT"
						[arrow]="true"
						[blurryThing]="true"
						(click)="close()"
					>
						Další kontakty
					</ch-blue-button>
				</div>

			</div>

		</div>


		<div class="overlay overlay--blue overlay--right">

			<div class="overlay-content overlay-content--right" role="form">

				<div class="form-heading">
					Nezávazná
					<strong>poptávka</strong>
				</div>

				<div class="form-subheading">
					Nechte nám tu kontakt, klidně i s krátkým popisem vaší situace a my se vám do dvou dní ozveme.
				</div>

				<form [formGroup]="form" (ngSubmit)="submitForm()" class="form-form" [class.form-form--shake]="formShake">

					<div class="form-body" >

						<div class="form-field form-field--required">
							<input class="form-input" type="text" autocomplete="name" formControlName="name" placeholder="Vaše jméno" id="form-name" />
							<label for="form-name" class="form-label">Vaše jméno</label>
						</div>

						<div class="form-field form-field--required">
							<input class="form-input" type="email" autocomplete="email" formControlName="email" placeholder="Váš e-mail" id="form-email" />
							<label for="form-email" class="form-label">Váš e-mail</label>
						</div>

						<div class="form-field">
							<input class="form-input" type="text" autocomplete="tel" formControlName="phone" placeholder="Vaše telefonní číslo" id="form-phone" />
							<label for="form-phone" class="form-label">Vaše telefonní číslo</label>
						</div>

						<div class="form-field form-field-radios">
							<div class="field-radios-label">
								Typ poptávky či dotazu
							</div>
							<label class="form-checkbox-row" *ngFor="let type of ContactFormTypeKeys">
								<input type="radio" formControlName="type" [value]="type" />
								{{ ContactFormTypeHumanNames[type] }}
							</label>
						</div>

						<div class="form-field form-field--required" *ngIf="form.get('type').value !== ContactFormType.JINY">
							<input class="form-input" type="text" autocomplete="address-level2" formControlName="city" placeholder="Místo realizace" id="form-city" />
							<label for="form-city" class="form-label">Místo realizace</label>
						</div>

						<div class="form-field form-field--required">
							<textarea class="form-input form-input--textarea" autocomplete="off" formControlName="message" placeholder="Vaše zpráva" id="form-message"></textarea>
							<label for="form-message" class="form-label">Zpráva nebo poznámka</label>
						</div>

						<div class="form-note">
							Odesláním souhlasíte se <a [routerLink]="PageUrls.LEGAL_OS_UDAJE">zpracováním osobních údajů</a>
						</div>

					</div>

					<div class="form-button">
						<ch-blue-button
							[white]="true"
							[arrow]="!sending && !showSuccess"
							[blurryThing]="false"
							type="submit"
						>
							{{ sending ? 'Odesílám' : (showSuccess ? 'Úspěšně odesláno!' : 'Odeslat') }}
							<img src="/assets/imgs/loading.gif" alt="Odesílám..." *ngIf="sending" class="form-sending-indicator" />
						</ch-blue-button>
					</div>

				</form>


			</div>



		</div>



		<button class="closer g-button-nebutton" (click)="close()">
			<img src="/assets/imgs/close-megamenu.svg" alt="Zavřít" />
		</button>


	</div>

</div>
