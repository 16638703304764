import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopInfoStripComponent } from './top-info-strip/top-info-strip.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { RouterModule } from '@angular/router';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { CommonStuffModule } from '../../common/common-stuff.module';
import { BlueButtonComponent } from './blue-button/blue-button.component';
import { MainMenuMobileButtonComponent } from './main-menu-mobile-button/main-menu-mobile-button.component';
import { MainMenuMobileComponent } from './main-menu-mobile/main-menu-mobile.component';
import { MainMenuMobileSecondaryPaneComponent } from './main-menu-mobile-secondary-pane/main-menu-mobile-secondary-pane.component';
import { ScrollDownComponent } from './scroll-down/scroll-down.component';
import { ScrollTargetComponent } from './scroll-target/scroll-target.component';
import { VerticalLabelComponent } from './vertical-label/vertical-label.component';
import { BlurredBlobComponent } from './blurred-blob/blurred-blob.component';
import { BulletedListComponent } from './bulleted-list/bulleted-list.component';
import { TextLinkWithArrowComponent } from './text-link-with-arrow/text-link-with-arrow.component';
import { YellowButtonComponent } from './yellow-button/yellow-button.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BookContentPageComponent } from './book-content-page/book-content-page.component';
import { PaginationComponent } from './pagination/pagination.component';
import { OkModule } from '../../submodules/ok-angular-tools';
import { PrevNextButtonsComponent } from './prev-next-buttons/prev-next-buttons.component';
import { PlusTextNoteComponent } from './plus-text-note/plus-text-note.component';
import { PlusHeadingComponent } from './plus-heading/plus-heading.component';
import { DownloadsGridItemComponent } from './downloads-grid-item/downloads-grid-item.component';
import { DataWithIconComponent } from './data-with-icon/data-with-icon.component';
import { PopupComponent } from './popup/popup.component';
import { GlossaryDetailComponent } from './glossary-detail/glossary-detail.component';
import { KarcherComponent } from './karcher/karcher.component';
import { NavTileWithImageComponent } from './nav-tile-with-image/nav-tile-with-image.component';
import { BlueRoundButtonComponent } from './blue-round-button/blue-round-button.component';
import { ScrollMenuComponent } from './scroll-items-menu/scroll-menu.component';
import { QuoteComponent } from './quote/quote.component';
import { SeparatorWithDotsComponent } from './separator-with-dots/separator-with-dots.component';
import { JobOfferDetailComponent } from './job-offer-detail/job-offer-detail.component';
import { ContactItemComponent } from './contact-item/contact-item.component';
import { PersonViewComponent } from './person-view/person-view.component';
import { GlossaryLetterItemComponent } from './glossary-letter-item/glossary-letter-item.component';
import { ContactLayerComponent } from './contact-layer/contact-layer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchLayerComponent } from '../../common/search/search-layer/search-layer.component';

@NgModule({
	declarations: [
		TopInfoStripComponent,
		MainMenuComponent,
		MegaMenuComponent,
		BlueButtonComponent,
		MainMenuMobileButtonComponent,
		MainMenuMobileComponent,
		MainMenuMobileSecondaryPaneComponent,
		ScrollDownComponent,
		ScrollTargetComponent,
		VerticalLabelComponent,
		BlurredBlobComponent,
		BulletedListComponent,
		TextLinkWithArrowComponent,
		YellowButtonComponent,
		BreadcrumbsComponent,
		BookContentPageComponent,
		PaginationComponent,
		PrevNextButtonsComponent,
		PlusTextNoteComponent,
		PlusHeadingComponent,
		DownloadsGridItemComponent,
		DataWithIconComponent,
		PopupComponent,
		GlossaryDetailComponent,
		KarcherComponent,
		NavTileWithImageComponent,
		BlueRoundButtonComponent,
		ScrollMenuComponent,
		QuoteComponent,
		SeparatorWithDotsComponent,
		JobOfferDetailComponent,
		ContactItemComponent,
		PersonViewComponent,
		GlossaryLetterItemComponent,
		ContactLayerComponent,
		SearchLayerComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		CommonStuffModule,
		OkModule,
		ReactiveFormsModule,
		FormsModule,
	],
	exports: [
		TopInfoStripComponent,
		MainMenuComponent,
		ScrollDownComponent,
		ScrollTargetComponent,
		VerticalLabelComponent,
		BlueButtonComponent,
		BlurredBlobComponent,
		BulletedListComponent,
		TextLinkWithArrowComponent,
		YellowButtonComponent,
		BreadcrumbsComponent,
		BookContentPageComponent,
		PaginationComponent,
		PrevNextButtonsComponent,
		PlusTextNoteComponent,
		DownloadsGridItemComponent,
		DataWithIconComponent,
		PopupComponent,
		GlossaryDetailComponent,
		KarcherComponent,
		PlusHeadingComponent,
		NavTileWithImageComponent,
		BlueRoundButtonComponent,
		ScrollMenuComponent,
		QuoteComponent,
		SeparatorWithDotsComponent,
		JobOfferDetailComponent,
		ContactItemComponent,
		PersonViewComponent,
		GlossaryLetterItemComponent,
		ContactLayerComponent,
		SearchLayerComponent,
	],
})
export class CommonComponentsModule {
}
