import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// https://medium.com/@adrianfaciu/custom-preloading-strategy-for-angular-modules-b3b5c873681a

export class AppPreloadingStrategy implements PreloadingStrategy {
	// tslint:disable-next-line:ban-types no-any
	preload(route: Route, load: Function): Observable<any> {
		if (route.path === 'kalkulacka-investice') {
			return of(null);
		}
		return timer(4000).pipe(mergeMap(_ => load()));
	}
}
