import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header/page-header.component';
import { CommonComponentsModule } from '../components/common/common-components.module';
import { HeroTextOnlyComponent } from './hero-text-only/hero-text-only.component';
import { HomepageProductsMenuComponent } from './homepage-products-menu/homepage-products-menu.component';
import { RouterModule } from '@angular/router';
import { CommonStuffModule } from '../common/common-stuff.module';
import { ClaimWithListComponent } from './claim-with-list/claim-with-list.component';
import { WaterdropVideoComponent } from './waterdrop-video/waterdrop-video.component';
import { OkModule } from '../submodules/ok-angular-tools';
import { ReferenceTilesComponent } from './reference-tiles/reference-tiles.component';
import { CaseStudyPreviewComponent } from './case-study-preview/case-study-preview.component';
import { SwiperModule } from 'swiper/angular';
import { FooterContactMapComponent } from './footer-contact-map/footer-contact-map.component';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { FooterSignatureComponent } from './footer-signature/footer-signature.component';
import { HeroTechnologyComponent } from './hero-technology/hero-technology.component';
import { BookComponent } from './book/book.component';
import { PlusTextNotesComponent } from './plus-text-notes/plus-text-notes.component';
import { DownloadsGridComponent } from './downloads-grid/downloads-grid.component';
import { TechnologiesNavComponent } from './technologies-nav/technologies-nav.component';
import { SmallReferencesComponent } from './small-references/small-references.component';
import { HotColdWaterComponent } from './hot-cold-water/hot-cold-water.component';
import { MegaVideoComponent } from './mega-video/mega-video.component';
import { GlossaryGridComponent } from './glossary-grid/glossary-grid.component';
import { PlusHeadingAndTilesMenuComponent } from './plus-heading-and-tiles-menu/plus-heading-and-tiles-menu.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { BoxEnterpreneurshipComponent } from './box-enterpreneurship/box-enterpreneurship.component';
import { HeroContentPageComponent } from './hero-content-page/hero-content-page.component';
import { ReferenceDetailComponent } from './reference-detail/reference-detail.component';
import { CaseStudiesGridComponent } from './case-studies-grid/case-studies-grid.component';
import { HeroCaseStudyComponent } from './hero-case-study/hero-case-study.component';
import { ImageAndListCollageComponent } from './image-and-list-collage/image-and-list-collage.component';
import { ImageAndQuoteComponent } from './image-and-quote/image-and-quote.component';
import { HistoryViewComponent } from './history-view/history-view.component';
import { TilesMenuComponent } from './tiles-menu/tiles-menu.component';
import { ContactPageMainPartComponent } from './contact-page-main-part/contact-page-main-part.component';
import { ContactMapsComponent } from './contact-maps/contact-maps.component';
import { PeopleGroupDisplayComponent } from './people-group-display/people-group-display.component';
import { GlossaryLetterComponent } from './glossary-letter/glossary-letter.component';
import { FormsModule } from '@angular/forms';


@NgModule({
	declarations: [
		PageHeaderComponent,
		HeroTextOnlyComponent,
		HomepageProductsMenuComponent,
		ClaimWithListComponent,
		WaterdropVideoComponent,
		ReferenceTilesComponent,
		CaseStudyPreviewComponent,
		FooterContactMapComponent,
		FooterNavComponent,
		FooterSignatureComponent,
		HeroTechnologyComponent,
		BookComponent,
		PlusTextNotesComponent,
		DownloadsGridComponent,
		TechnologiesNavComponent,
		SmallReferencesComponent,
		HotColdWaterComponent,
		MegaVideoComponent,
		GlossaryGridComponent,
		PlusHeadingAndTilesMenuComponent,
		GalleryComponent,
		BoxEnterpreneurshipComponent,
		HeroContentPageComponent,
		ReferenceDetailComponent,
		CaseStudiesGridComponent,
		HeroCaseStudyComponent,
		ImageAndListCollageComponent,
		ImageAndQuoteComponent,
		HistoryViewComponent,
		TilesMenuComponent,
		ContactPageMainPartComponent,
		ContactMapsComponent,
		PeopleGroupDisplayComponent,
		GlossaryLetterComponent,
	],
	imports: [
		CommonModule,
		CommonComponentsModule,
		RouterModule,
		CommonStuffModule,
		OkModule,
		SwiperModule,
		NgxMasonryModule,
		FormsModule,
	],
	exports: [
		PageHeaderComponent,
		HeroTextOnlyComponent,
		HomepageProductsMenuComponent,
		ClaimWithListComponent,
		WaterdropVideoComponent,
		ReferenceTilesComponent,
		CaseStudyPreviewComponent,
		FooterContactMapComponent,
		FooterNavComponent,
		FooterSignatureComponent,
		HeroTechnologyComponent,
		BookComponent,
		PlusTextNotesComponent,
		DownloadsGridComponent,
		TechnologiesNavComponent,
		SmallReferencesComponent,
		HotColdWaterComponent,
		MegaVideoComponent,
		GlossaryGridComponent,
		PlusHeadingAndTilesMenuComponent,
		GalleryComponent,
		BoxEnterpreneurshipComponent,
		HeroContentPageComponent,
		ReferenceDetailComponent,
		CaseStudiesGridComponent,
		HeroCaseStudyComponent,
		ImageAndListCollageComponent,
		ImageAndQuoteComponent,
		HistoryViewComponent,
		TilesMenuComponent,
		ContactPageMainPartComponent,
		ContactMapsComponent,
		PeopleGroupDisplayComponent,
		GlossaryLetterComponent,
	],
})
export class BlocksModule {
}
