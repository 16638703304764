<header
	class="body mainmenu"
	[class.body--fixed]="fixed"
	[class.mainmenu--fixed]="fixed"
	[class.mainmenu--with-megamenu]="megamenuOpen"
	[class.mainmenu--with-mobilemenu]="mobileMenuOpen"
	[class.mainmenu--animated]="animated"
	[class.mainmenu--enter]="enter"
	[class.mainmenu--before-enter]="beforeEnter"
	[class.mainmenu--blue]="blue"
	role="banner"
>
	<div class="main-part" [class.main-part--bg]="megamenuOpen">
		<div class="g-container box">
			<div
				class="logo-space"
				[class.logo-space--fixed]="fixed"
			>
				<a
					[routerLink]="['/']"
					class="logo"
					[class.logo--fixed]="fixed"
					[class.logo--hidden]="(secondaryPaneVisible$ | async) === true && (menuTypeStatus$ | async) === 's'"
				>
					<img
						[src]="((blue && !fixed && !megamenuOpen && !mobileMenuOpen) ? '/assets/imgs/logo-white.svg' : '/assets/imgs/logo.svg')"
						alt="Logo ChemieStar - 30 let na trhu!"
						class="logo-img"
					/>
				</a>
				<button
					*ngIf="live"
					class="g-button-nebutton back-button"
					[class.back-button--hidden]="(secondaryPaneVisible$ | async) === false || (menuTypeStatus$ | async) === 'l'"
					(click)="clickedBackButton()"
				>
					<span class="back-button-arrow g-arrow g-arrow--blue g-arrow--left"></span>
					Technologie
				</button>
			</div>
			<nav aria-label="Hlavní menu" class="main-menu-part" role="navigation">
				<ul class="g-list-nelist menu">
					<li *ngFor="let item of menu; let index = index;" class="item">
						<ng-container *ngIf="item.expandable">
							<span
								class="link link--with-arrow"
								[class.link--with-arrow-expanded]="megamenuOpen"
								[class.link--active]="technologieLinkActive"
								(click)="toggleOpenMegaMenu()"
							>
								<span class="link-label">
									{{ item.text }}
								</span>
								<span class="link-arrow">

								</span>
							</span>
						</ng-container>
						<ng-container *ngIf="!item.expandable">
							<a
								[routerLink]="item.link"
								class="link link--hoverable"
								routerLinkActive="link--active"
							>
								<span class="link-label">
									{{ item.text }}
								</span>
							</a>
						</ng-container>

					</li>
					<li class="item" (click)="clickedSearchButton()">
						<span class="link link--icon">
							<img src="/assets/imgs/search-icon-blue.svg" alt="Vyhledávání" class="search-icon" *ngIf="!blue" />
							<img src="/assets/imgs/search-icon-white.svg" alt="Vyhledávání" class="search-icon" *ngIf="blue" />
						</span>
					</li>
				</ul>
			</nav>
			<ch-main-menu-mobile-button
				class="mobile-menu-button"
				[open]="mobileMenuOpen"
				[blue]="blue && !fixed && !mobileMenuOpen"
				(click)="toggleMobileMenu()"
			></ch-main-menu-mobile-button>

		</div>
	</div>
	<div class="megamenu"
	     [class.megamenu--visible]="megamenuOpen"
	     [class.megamenu--with-strip]="withStrip"
	     [class.megamenu--slim]="fixed"
	     #megamenu
	>
		<div class="g-container">
			<div class="dotted-bg g-dotted-bg"></div>
		</div>
		<ch-mega-menu [visible]="megamenuOpen"></ch-mega-menu>
	</div>
</header>

<div class="megamenu-overlay" [class.megamenu-overlay--visible]="megamenuOpen" (click)="closeMegaMenu()">
	<button class="close-megamenu g-button-nebutton" title="Zavřít menu"></button>
</div>

<div class="mobile-menu" [class.mobile-menu--visible]="mobileMenuOpen" *ngIf="live">
	<ch-main-menu-mobile
		[open]="mobileMenuOpen"
		[secondaryPaneVisible$]="secondaryPaneVisible$"
		[stripData]="stripData"
	></ch-main-menu-mobile>
</div>

<div
	class="space-placeholder"
	[class.space-placeholder--fixed]="fixed"
></div>
