<div class="body" [class.body--appearing]="appearing">

	<div class="pane pane--primary pane-box" [class.pane--inactive]="(secondaryPaneVisible$ | async) === true" role="navigation">

		<div class="dots g-dotted-bg">
		</div>

		<nav class="main-menu" [class.main-menu--open]="open" [style.--total-items]="menu.length">
			<ul class="g-list-nelist">
				<li *ngFor="let menuItem of menu; let index = index;">
					<ng-container *ngIf="menuItem.expandable">
						<span class="main-link"
						      [style.--index]="index + 1"
						      (click)="toggleProductMenu()"
						>
							{{ menuItem.text }}
							<span class="main-link-arrow"></span>
						</span>
					</ng-container>
					<ng-container *ngIf="!menuItem.expandable">
						<a
							[routerLink]="menuItem.link"
							class="main-link"
							routerLinkActive="main-link--active"
							[style.--index]="index + 1"
						>
							{{ menuItem.text }}
						</a>
					</ng-container>
				</li>
				<li>
					<span class="main-link" [style.--index]="menu.length + 1" (click)="clickedSearch()">
						<img src="/assets/imgs/search-icon-blue.svg" alt="Vyhledávání" class="search-icon" />
						Hledat
					</span>
				</li>
			</ul>
		</nav>

		<div class="bottom-dots g-dotted-bg" [style.--total-items]="menu.length">

		</div>

		<ng-container *ngIf="stripData">
			<div
				class="strip-pos"
				[class.strip-pos--open]="open"
				[style.--total-items]="menu.length"
			>
				<div class="strip" [innerHTML]="stripData.text">
				</div>
				<a [href]="stripData.link" class="eshop-button">
					E-shop <strong>Kärcher Center</strong>
					<span class="eshop-arrow"></span>
				</a>
			</div>
		</ng-container>

	</div>

	<div
		class="pane pane--secondary"
		[class.pane--inactive]="(secondaryPaneVisible$ | async) === false || (secondaryPaneVisibleDelayed$ | async) === false"
		[class.pane--in-dom]="(secondaryPaneVisible$ | async) || (secondaryPaneVisibleDelayed$ | async) "
	>

		<div class="dots g-dotted-bg">
		</div>

		<ch-main-menu-mobile-secondary-pane></ch-main-menu-mobile-secondary-pane>


	</div>


</div>
