/* tslint:disable:no-any */
import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { HtmlHeadService } from './html-head.service';
import { HtmlHeadTransformInterface } from './html-head-transform.interface';
import { HtmlHeadTransformToken } from './html-head-transform.token';
import { HtmlHeadConfigToken } from './html-head-config.token';
import { HtmlHeadConfigInterface } from './html-head-config.interface';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'ok-html-head-data',
	templateUrl: './html-head-data.component.html',
	styleUrls: ['./html-head-data.component.scss'],
})
export class HtmlHeadDataComponent implements OnInit, OnChanges {

	@Input()
	public title = '';

	@Input()
	public description = '';

	@Input()
	public robots: string | boolean = true;

	@Input()
	public canonicalUrl = '';

	@Input()
	public prevUrl = '';

	@Input()
	public nextUrl = '';

	@Input()
	public socialTitle = '';

	@Input()
	public socialDescription = '';

	@Input()
	public socialSiteName = '';

	@Input()
	public image = '';

	@Input()
	public author = '';

	@Input()
	public structuredData: any = null;

	@Input()
	public contextData: any = null;

	constructor(
		protected htmlHeadService: HtmlHeadService,
		@Inject(HtmlHeadTransformToken) @Optional() protected transform: HtmlHeadTransformInterface,
		@Inject(HtmlHeadConfigToken) @Optional() protected config: HtmlHeadConfigInterface,
	) {
	}

	ngOnInit(): void {
	}

	public enterPage(): void {
		this.applyData();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.applyData();
	}

	protected applyData(): void {
		this.htmlHeadService.setTitle(this.transformValue(this.title, this.transform?.transformTitle));
		this.htmlHeadService.setDescription(this.transformValue(this.description, this.transform?.transformDescription));
		this.htmlHeadService.setRobots(this.transformValue(this.robots, this.transform?.transformRobots));
		this.htmlHeadService.setCanonicalUrl(this.transformValue(this.canonicalUrl, this.transform?.transformCanonicalUrl));
		this.htmlHeadService.setPaginationUrls(
			this.transformValue(this.prevUrl, this.transform?.transformPrevUrl),
			this.transformValue(this.nextUrl, this.transform?.transformNextUrl),
		);
		let socTitle = this.transformValue(this.socialTitle, this.transform?.transformSocialTitle);
		let socDesc = this.transformValue(this.socialDescription, this.transform?.transformSocialDescription);
		this.htmlHeadService.setSocialTags(
			socTitle
				? socTitle
				: this.transformValue(this.title, this.transform?.transformTitle)
			,
			socDesc
				? socDesc
				: this.transformValue(this.description, this.transform?.transformDescription)
			,
			this.transformValue(this.socialSiteName, this.transform?.transformSocialSiteName),
		);
		this.htmlHeadService.setImage(this.transformValue(this.image, this.transform?.transformImage));
		this.htmlHeadService.setAuthor(this.transformValue(this.author, this.transform?.transformAuthor));
		this.htmlHeadService.setStructuredData(this.structuredData);

		this.tryDataLayer();
	}

	protected transformValue(value: any, transformFn?: (input, contextData) => any): any {
		if (transformFn) {
			return transformFn(value, this.contextData);
		}
		return value;
	}

	protected tryDataLayer(): any {
		if (this.config?.pushToDataLayer) {
			let url = window.location?.toString();
			let dataLayerName = this.config?.dataLayerVarName || 'dataLayer';
			if (window[dataLayerName]?.push) {
				window[dataLayerName].push({
					page: {
						pageURL: url,
						pageTitle: this.title,
					},
					event: 'pageView',
				});
			}
		}
	}

}
