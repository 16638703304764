import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { AppPreloadingStrategy } from './routing/app-preload-strategy';



@NgModule({
	imports: [RouterModule.forRoot(
		routes,
		{
			scrollPositionRestoration: 'enabled',
			preloadingStrategy: AppPreloadingStrategy,
		}
	)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
