import { asyncScheduler, fromEvent, merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, throttleTime } from 'rxjs/operators';

export interface WindowSizeClass {
	id: string;
	minWidth?: number;
	maxWidth?: number;
}

export function windowSizeClassWatch(sizeClasses: WindowSizeClass[]): Observable<WindowSizeClass | null> {
	return windowSizeWatch()
		.pipe(
			map(
				(width) => sizeClasses.find(
					(c) => (
						(!c.minWidth || c.minWidth <= width)
						&&
						(!c.maxWidth || c.maxWidth > width)
					),
				),
			),
			distinctUntilChanged(),
		);
}

export function windowSizeWatch(throttle = 200): Observable<number> {

	let stream$ = fromEvent(window, 'resize')
		.pipe(
			startWith(true),
		);

	if (throttle) {
		stream$ = stream$.pipe(
			throttleTime(
				throttle,
				asyncScheduler,
				{leading: true, trailing: true},
			),
		);
	}

	return stream$.pipe(
		map(
			(_) => window.innerWidth,
		),
		distinctUntilChanged(),
	);

}

export function windowHeightWatch(throttle = 200, watchOnScroll = false): Observable<number> {

	let stream$ = fromEvent(window, 'resize')
		.pipe(
			startWith(true),
		);

	if (watchOnScroll) {
		let scrollStream$ = fromEvent(window, 'scroll');
		stream$ = merge(stream$, scrollStream$);
	}

	if (throttle) {
		stream$ = stream$.pipe(
			throttleTime(
				throttle,
				asyncScheduler,
				{leading: true, trailing: true},
			),
		);
	}

	return stream$.pipe(
		map(
			(_) => window.innerHeight,
		),
		distinctUntilChanged(),
	);

}
