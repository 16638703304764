export function supportsWebP(): boolean {
	if (!didCheck) {
		didCheck = true;
		check().then((s) => { supports = s; });
	}
	return supports;
}

function supportByUserAgent(ua: string): boolean {
	if (ua.match(/trident/i) && ua.match(/windows/i)) {
		// IE 11
		return false;
	}
	if (ua.match(/OS X 10/i)) {
		// Pre-Big Sur OS X
		if (ua.match(/safari/i) && !ua.match(/chrome/i) && !ua.match(/chromium/i)) {
			// Safari
			return false;
		}
	}
	return true;
}

async function check(): Promise<boolean> {
	if (!self.createImageBitmap) {
		return false;
	}
	const blob = await fetch(webpData).then(r => r.blob());
	supports = await createImageBitmap(blob).then(() => true, () => false);
	return supports;
}

let supports = false;
let didCheck = false;

if (navigator?.userAgent) {
	supports = supportByUserAgent(navigator.userAgent);
}

const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';

