import { HtmlHeadTransformInterface } from '../submodules/ok-angular-tools/features/html-head/html-head-transform.interface';

// tslint:disable:no-any

export class HtmlHeadTransform implements HtmlHeadTransformInterface {
	transformTitle(title: string, contextData?: any): string {
		return title + ' | CHEMIE STAR';
	}

	transformAuthor(author: string, contextData?: any): string {
		return author + (author ? ' - ' : '') + 'CHEMIE STAR, spol. s r. o.';
	}
}
