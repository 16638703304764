import { Component, Input, OnInit } from '@angular/core';
import { ImageAsset } from '../../../models/image-asset';
import { RouterLinkStrings } from '../../../models/types/router-link-strings';

@Component({
	selector: 'ch-search-result-small',
	templateUrl: './search-result-small.component.html',
	styleUrls: ['./search-result-small.component.scss'],
})
export class SearchResultSmallComponent implements OnInit {

	@Input()
	title: string;

	@Input()
	subtitle: string;

	@Input()
	blueSubtitle: string;

	@Input()
	image: ImageAsset;

	@Input()
	link: RouterLinkStrings;

	@Input()
	subtitleLink?: string;

	@Input()
	blueSubtitleLink?: string;

	@Input()
	roundImage = false;

	constructor() {
	}

	ngOnInit(): void {
	}

	clickedBlueSubtitle($event: MouseEvent): void {
		if (this.blueSubtitleLink) {
			window.location.assign(this.blueSubtitleLink);
			$event.stopPropagation();
			$event.preventDefault();
		}
	}

	clickedSubtitle($event: MouseEvent): void {
		if (this.subtitleLink) {
			window.location.assign(this.subtitleLink);
			$event.stopPropagation();
			$event.preventDefault();
		}

	}
}
