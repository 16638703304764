import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MainMenuItem, MainMenuItems } from '../../../routing/menu-items';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TopInfoStripData } from '../top-info-strip/top-info-strip-data';
import { SearchService } from '../../../common/search/search.service';

@Component({
	selector: 'ch-main-menu-mobile',
	templateUrl: './main-menu-mobile.component.html',
	styleUrls: ['./main-menu-mobile.component.scss'],
})
export class MainMenuMobileComponent implements OnInit, OnChanges {

	public menu: MainMenuItem[] = [
		{
			text: 'Technologie',
			expandable: true,
		},
		...MainMenuItems,
	];

	@Input()
	public open = false;

	@Input()
	public secondaryPaneVisible$: BehaviorSubject<boolean>;

	@Input()
	public stripData: TopInfoStripData;

	public appearing = false;

	public secondaryPaneVisibleDelayed$: Observable<boolean>;

	constructor(
		protected searchService: SearchService,
	) {
	}

	ngOnInit(): void {
		this.secondaryPaneVisibleDelayed$ = this.secondaryPaneVisible$.pipe(delay(100));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.open) {
			this.appearing = true;
			setTimeout(
				() => {
					this.appearing = false;
				},
				500,
			);
		}
	}

	toggleProductMenu(): void {
		this.secondaryPaneVisible$.next(!this.secondaryPaneVisible$.value);
	}

	clickedSearch(): void {
		this.searchService.openSearchField();
	}
}
