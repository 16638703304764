import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlHeadDataComponent } from '../features/html-head/html-head-data.component';
import { HtmlHeadService } from '../features/html-head/html-head.service';


@NgModule({
	declarations: [
		HtmlHeadDataComponent,
	],
	imports: [
		CommonModule,
	],
	exports: [
		HtmlHeadDataComponent,
	],
	providers: [
		HtmlHeadService,
	],
})
export class OkHtmlHeadModule {
}
