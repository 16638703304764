import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { createGlossaryItem, GlossaryItem } from '../../models/glossary-item';
import { createPerson, Person } from '../../models/person';
import { createReferenceCompany, ReferenceCompany } from '../../models/reference-company';
import { CaseStudyPreview, createCaseStudyPreview } from '../../models/case-study-preview';
import { createDownloadItem, DownloadItem } from '../../models/download-item';
import { MegaMenuItem, MegaMenuItems } from '../../routing/menu-items';

export interface ResultsOfOneType<Type> {
	items: Type[];
	count: number;
}

export interface SearchResult {
	glossary: ResultsOfOneType<GlossaryItem>;
	person: ResultsOfOneType<Person>;
	references: ResultsOfOneType<ReferenceCompany>;
	caseStudies: ResultsOfOneType<Omit<CaseStudyPreview, 'buttonText'>>;
	downloads: ResultsOfOneType<DownloadItem>;
	technologies: ResultsOfOneType<MegaMenuItem>;
	totalItemsCount: number;
	searchTerm: string;
}

@Injectable({
	providedIn: 'root',
})
export class SearchEngineService {

	constructor(
		protected api: ApiService,
	) {
		window['search'] = this;
	}

	public async search(term: string, allResults = false): Promise<SearchResult> {

		let queryParts: string[] = [];
		queryParts.push(this.createPartialQuery('glossary', 'glossaryItems', ['name', 'shortDescription', 'id', 'slug'], allResults ? 100 : 8));
		queryParts.push(this.createPartialQuery('person', 'persons', ['name', 'id', 'email', 'phone', 'position', 'photo { handle }'], allResults ? 100 : 8));
		queryParts.push(this.createPartialQuery('references', 'references', ['id', 'name', 'company', 'logo { handle }', 'slug'], allResults ? 100 : 8));
		queryParts.push(this.createPartialQuery('caseStudies', 'caseStudies', ['id', 'name', 'slug', 'subtitle', 'buttonText', 'image { handle }'], allResults ? 100 : 4));
		queryParts.push(this.createPartialQuery('downloads', 'downloads', [
			'name',
			'displayInBrowser',
			'youtubeLink',
			'icon { handle }',
			'file {	size url fileName handle}',
			'downloadCategory { name }',
		], allResults ? 100 : 12));
		queryParts.push(this.createPartialQuery('technologies', 'technologyPages', ['id', 'technology'], allResults ? 100 : 5));

		let q = `
			query($term: String!) {
				${queryParts.join('\n ')}
			}
		`;

		let response = await this.api.call(
			q,
			{
				term,
			},
		);

		return {
			glossary: {
				count: response.glossary.aggregate.count,
				items: response.glossary.edges.map(d => createGlossaryItem(d.node)),
			},
			person: {
				count: response.person.aggregate.count,
				items: response.person.edges.map(d => createPerson(d.node)),
			},
			references: {
				count: response.references.aggregate.count,
				items: response.references.edges.map(d => createReferenceCompany(d.node)),
			},
			caseStudies: {
				count: response.caseStudies.aggregate.count,
				items: response.caseStudies.edges.map(d => createCaseStudyPreview(d.node)),
			},
			downloads: {
				count: response.downloads.aggregate.count,
				items: response.downloads.edges.map(d => createDownloadItem(d.node)),
			},
			technologies: {
				count: response.technologies.aggregate.count,
				items: response.technologies.edges.map(d => MegaMenuItems.find((testedItem) => (testedItem.technologyPageType === d.node.technology))),
			},
			totalItemsCount:
				response.glossary.aggregate.count
				+ response.person.aggregate.count
				+ response.references.aggregate.count
				+ response.caseStudies.aggregate.count
				+ response.downloads.aggregate.count
				+ response.technologies.aggregate.count
			,
			searchTerm: term,
		};

	}

	protected createPartialQuery(alias: keyof SearchResult, contentTypePlural: string, fields: string[], count: number): string {
		return `
			${alias}: ${contentTypePlural}Connection(first: ${count}, where: { _search: $term }) {
				edges {
					node {
						${fields.join('\n ')}
					}
				}
				aggregate {
					count
				}
			}
		`;
	}

}
