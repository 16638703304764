import { HtmlString } from './types/html-string';
import { factory } from '../submodules/ok-tools';

export interface LegalPageContent {
	slug: string;
	heading: string;
	content: HtmlString;
	shortHeading: string;
	metaDescription: string;
}


// tslint:disable-next-line:no-any
export function createLegalPageContent(source: any): LegalPageContent {

	return factory(
		source,
		{
			string: ['heading', 'content', 'slug', 'shortHeading', 'metaDescription'],
			from: {
				content: (s) => s.content?.html,
			},
		},
	);

}
