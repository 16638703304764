import { NgModule } from '@angular/core';
import { PhotoswipeService } from '../features/photoswipe/photoswipe-service';
import { PhotoswipeElementComponent } from '../features/photoswipe/photoswipe-element';

@NgModule({
	exports: [
		PhotoswipeElementComponent,
	],
	declarations: [
		PhotoswipeElementComponent,
	],
	providers: [
		PhotoswipeService,
	],
})
export class PhotoswipeModule {

}
