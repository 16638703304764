import { factory } from '../submodules/ok-tools';

export interface GlossaryItem {
	id: string;
	slug: string;
	name: string;
	shortDescription: string;
}

// tslint:disable-next-line:no-any
export function createGlossaryItem(source: any): GlossaryItem {
	return factory<GlossaryItem>(
		source,
		{
			string: ['id', 'slug', 'name', 'shortDescription'],
		}
	);
}
