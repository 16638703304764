import { Observable } from 'rxjs';
import { windowScrollClassWatch } from '../../../submodules/ok-angular-tools/tools/scroll-watch';
import { map } from 'rxjs/operators';

export type ScrollState = 'up'|'almost-up'|'down';

export function createScrollWatch(): Observable<ScrollState> {
	return windowScrollClassWatch([
		{
			id: 'up',
			maxScroll: 200,
		},
		{
			id: 'almost-up',
			minScroll: 200,
			maxScroll: 400,
		},
		{
			id: 'down',
			minScroll: 400,
		}
	]).pipe(
		map(scrollClass => (scrollClass.id as ScrollState))
	);
}
