import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as bodyScrollLock from 'body-scroll-lock';
import { ActivationEnd, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { MainMenuItem, MainMenuItems } from '../../../routing/menu-items';
import { BehaviorSubject, Observable } from 'rxjs';
import { windowSizeClassWatch } from '../../../submodules/ok-angular-tools/tools/window-size-watch';
import { CssBreakpoints } from '../../../common/css-variables';
import { isScullyRunning } from '@scullyio/ng-lib';
import { TopInfoStripData } from '../top-info-strip/top-info-strip-data';
import { SearchService } from '../../../common/search/search.service';


@Component({
	selector: 'ch-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input()
	public fixed = false;

	@Input()
	public animated = false;

	@Input()
	public beforeEnter = false;

	@Input()
	public enter = false;

	@Input()
	public withStrip = false;

	@Input()
	public stripData: TopInfoStripData;

	@Input()
	public blue = false;

	@ViewChild('megamenu')
	public megamenuElement: ElementRef;

	public menu: MainMenuItem[] = [
		{
			text: 'Technologie',
			expandable: true,
		},
		...MainMenuItems,
	];

	public menuTypeStatus$: Observable<'s' | 'l'>;

	public megamenuOpen = false;
	public mobileMenuOpen = false;

	public technologieLinkActive = false;

	public secondaryPaneVisible$ = new BehaviorSubject<boolean>(false);

	public live = false;

	protected escHandler;

	protected subsink = new SubSink();

	constructor(
		protected router: Router,
		protected searchService: SearchService,
	) {

		this.menuTypeStatus$ = windowSizeClassWatch([
			{id: 's', maxWidth: CssBreakpoints.Md},
			{id: 'l', minWidth: CssBreakpoints.Md},
		]).pipe(map((c) => (c.id as 's' | 'l')));

		this.subsink.sink = this.menuTypeStatus$.pipe(distinctUntilChanged()).subscribe(
			() => {
				this.mobileMenuOpen = false;
				this.megamenuOpen = false;
			},
		);
	}

	ngOnInit(): void {
		this.escHandler = () => {
			if (this.megamenuOpen) {
				this.closeMegaMenu();
			}
		};
		this.subsink.sink = this.router.events.pipe(filter((e) => (e instanceof ActivationEnd))).subscribe(
			(event: ActivationEnd) => {
				this.technologieLinkActive = !!(event.snapshot?.data?.technologie);
				this.closeMegaMenu();
				this.closeMobileMenu();
			},
		);
		setTimeout(
			() => {
				if (!isScullyRunning()) {
					this.live = true;
				}
			},
			100,
		);
		document.body.addEventListener('keyup', this.escHandler);
	}

	ngAfterViewInit(): void {
		this.updateBodyScrollLock();
	}

	ngOnDestroy(): void {
		document.body.removeEventListener('keyup', this.escHandler);
		this.subsink.unsubscribe();
	}

	toggleOpenMegaMenu(): void {
		this.megamenuOpen = !this.megamenuOpen;
		this.secondaryPaneVisible$.next(false);
		this.updateBodyScrollLock();
	}

	closeMegaMenu(): void {
		this.megamenuOpen = false;
		this.secondaryPaneVisible$.next(false);
		this.updateBodyScrollLock();
	}

	closeMobileMenu(): void {
		this.mobileMenuOpen = false;
		this.secondaryPaneVisible$.next(false);
		this.updateBodyScrollLock();
	}

	protected updateBodyScrollLock(): void {
		if (this.megamenuOpen || this.mobileMenuOpen) {
			this.menuTypeStatus$.pipe(first()).subscribe(
				(size) => {
					if (size === 'l') {
						bodyScrollLock.disableBodyScroll(this.megamenuElement.nativeElement);
					}
				}
			);
		} else {
			bodyScrollLock.enableBodyScroll(this.megamenuElement.nativeElement);
		}
	}

	toggleMobileMenu(): void {
		this.mobileMenuOpen = !this.mobileMenuOpen;
		this.secondaryPaneVisible$.next(false);
		this.updateBodyScrollLock();
	}

	clickedBackButton(): void {
		this.secondaryPaneVisible$.next(false);
	}

	clickedSearchButton(): void {
		this.searchService.openSearchField();
	}
}
