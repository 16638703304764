import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContactLayerService } from '../../../common/contact-layer/contact-layer.service';
import { ActivationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataService } from '../../../data/data.service';
import { ContactInfo } from '../../../models/contact-info';
import * as bodyScrollLock from 'body-scroll-lock';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageUrls } from '../../../routing/page-urls';
import { ContactFormService } from '../../../data/contact-form.service';
import { delay } from 'src/app/submodules/ok-tools/src/delay';
import { ContactFormType, ContactFormTypeHumanNames } from '../../../models/contact-form-type';


@Component({
	selector: 'ch-contact-layer',
	templateUrl: './contact-layer.component.html',
	styleUrls: ['./contact-layer.component.scss'],
})
export class ContactLayerComponent implements OnInit {

	public show = false;
	public showInner = false;

	public contact: ContactInfo;

	public escHandler;

	@ViewChild('position')
	public positionElement: ElementRef;

	public form: FormGroup;

	public formShake = false;

	public PageUrls = PageUrls;
	public ContactFormType = ContactFormType;
	public ContactFormTypeHumanNames = ContactFormTypeHumanNames;
	public ContactFormTypeKeys = Object.values(ContactFormType);

	public sending = false;
	public showSuccess = false;

	pu;


	constructor(
		protected service: ContactLayerService,
		protected router: Router,
		protected dataService: DataService,
		protected contactForm: ContactFormService,
	) {

		this.form = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [Validators.required, Validators.email]),
			type: new FormControl('', Validators.required),
			city: new FormControl('', (cityControl) => {
				let city = cityControl.value;
				let form = cityControl.root;
				if (form && form !== cityControl) {
					let type = form.get('type').value;
					if (type !== ContactFormType.JINY && !city) {
						return {
							cityRequired: true,
						};
					}
				}
				return {};
			}),
			phone: new FormControl('', Validators.required),
			message: new FormControl('', Validators.required),
		});

		this.form.addValidators(
			(form) => {
				let city = form.get('city').value;
				let type = form.get('type').value;
				if (type !== ContactFormType.JINY && !city) {
					return {
						cityRequired: true,
					};
				}
				return {};
			},
		);

	}

	ngOnInit(): void {
		this.service.registerComponent(this);
		this.escHandler = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && this.show) {
				this.close();
			}
		};
		document.addEventListener('keyup', this.escHandler);
		this.router.events.pipe(filter((e) => (e instanceof ActivationStart))).subscribe(
			() => {
				this.close();
			},
		);
	}

	public open(): void {
		if (this.show) {
			return;
		}
		this.show = true;


		setTimeout(
			() => {
				this.showInner = true;
				bodyScrollLock.disableBodyScroll(this.positionElement.nativeElement);
			},
			50,
		);
		this.dataService.getContactInfo().then(
			(c) => {
				this.contact = c;
			},
		);
	}

	public close(): void {
		if (!this.showInner) {
			return;
		}

		bodyScrollLock.enableBodyScroll(this.positionElement.nativeElement);

		this.showInner = false;
		setTimeout(
			() => {
				this.show = false;
			},
			1000,
		);
	}

	public async submitForm(): Promise<void> {

		if (this.sending) {
			return;
		}

		this.form.updateValueAndValidity();
		this.form.get('city').updateValueAndValidity();

		if (!this.form.valid) {
			this.formShake = true;
			Object.values(this.form.controls).forEach(
				(v) => {
					v.markAsTouched();
				},
			);
			setTimeout(
				() => {
					this.formShake = false;
					window.alert('Zkontrolujte prosím, že jsou všechna políčka vyplněna správně.');
				},
				600,
			);
			return;
		}

		this.sending = true;

		await delay(1000);

		let values = {...this.form.value};
		if (ContactFormTypeHumanNames[values.type]) {
			values.type = ContactFormTypeHumanNames[values.type];
		}

		try {
			await this.contactForm.send(values);
			this.sending = false;
			this.showSuccess = true;
			setTimeout(
				() => {
					this.showSuccess = false;
					this.form.reset();
				},
				2000,
			);
		} catch (e) {
			this.sending = false;
			window.alert('Nastala chyba při odesílání formuláře. \n\n' + e?.message);
		}


	}

}
