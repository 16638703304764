import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DownloadItem } from '../../../models/download-item';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'ch-downloads-grid-item',
	templateUrl: './downloads-grid-item.component.html',
	styleUrls: ['./downloads-grid-item.component.scss'],
})
export class DownloadsGridItemComponent implements OnInit {

	@Input()
	item: DownloadItem;

	@Input()
	background = true;

	@Input()
	showCategory = false;

	@Output()
	openVideoPopup = new EventEmitter<SafeResourceUrl>();

	constructor(
		protected sanitizer: DomSanitizer,
	) {
	}

	ngOnInit(): void {
	}

	clickedItem($event: MouseEvent): void {
		if (this.item.isYoutubeLink) {
			$event.preventDefault();
			this.openVideoPopup.emit(
				this.sanitizer.bypassSecurityTrustResourceUrl(this.item.url),
			);
		}
	}
}
